export const engineVolume = (volume: number) => volume.toFixed(1);
export const numberFormat = (value: number): string => {
  if (!value) return '';
  return new Intl.NumberFormat('ru-RU').format(value);
};
export const creditPrice = (price: number) => {
  const percent: number = 4.9;
  const n: number = 96;
  const i: number = percent / 12 / 100;
  const K: number = (i * (1 + i) ** n) / ((1 + i) ** n - 1);
  return numberFormat(Math.round(K * price));
};

export const useRubles = (value: number) => (value ? `${numberFormat(value)} ₽` : '0 ₽');

export const useCalculate = (price: number | undefined, payment: number, period: number, creditPercent = parseFloat(useNuxtApp().$settings.credit_percent), isInstallment: boolean = false) => {
  if (price) {
    const creditProc: number = isInstallment ? 0.001 : creditPercent;
    const car_price: number = price;
    const creditTime: number = period;
    const firstPay: number = payment;
    let total: number = 0;
    const i: number = creditProc / 12 / 100;
    const n: number = creditTime;
    let S: number;
    if (firstPay !== 0) {
      S = car_price - (car_price * firstPay) / 100;
    } else {
      S = car_price;
    }
    let K: number = 0;
    if (car_price) {
      K = (i * (1 + i) ** n) / ((1 + i) ** n - 1);
      total = Math.round(K * S);
      return total;
    }
    return 0;
  }
  return 0;
};