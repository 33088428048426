import { banks as banksPrepared } from '#nuxt-prepare';
import type { Bank } from '~/types/graphql';

export type BankSlug =
  | 'sberbank'
  | 't-bank'
  | 'alfa-bank'
  | 'atb-bank'
  | 'drive-click-bank'
  | 'vtb'
  | 'gazprombank'
  | 'zenit-bank'
  | 'bank-ingosstrah'
  | 'raiffeisen-bank'
  | 'otkrytie-bank'
  | 'bank-ekspobank'
  | 'primsocbank'
  | 'avtokredit-v-banke-oranzevyi'
  | 'bank-absolut'
  | 'bank-mkb'
  | 'bank-avangard'
  | 'bank-otp'
  | 'rosselhozbank'
  | 'pochta-bank'
  | 'centr-invest'
  | 'bank-renessanse'
  | 'loko-bank'
  | 'sovkombank'
  | 'uralsib-bank'
  | 'bank-houm-kredit'
  | 'bank-promsviaz';

export type TBanks = {
  [key in BankSlug]: Bank & { isThemeDependent: boolean };
}

type BanksState = {
    banks: TBanks;
};
export const useBanksStore = defineStore('banks', {
  state: (): BanksState => ({
    banks: banksPrepared as unknown as TBanks,
  }),

  getters: {},

  actions: {
    fetchBanks(): BanksState['banks'] {
      return this.banks;
    },
    getBySlug(slug: BankSlug) {
      return this.banks[slug];
    },
    normalize(bank: Pick<Bank, 'name'>) {
      const normalized = {
        'Тинькофф Банк': 'Тинькофф',
        'Газпромбанк Автолизинг': 'Газпромбанк',
      } as Record<string, string>;
      return normalized[bank.name] || bank.name;
    },
  },
});
