// noinspection t

import type { Offer } from '~/types/graphql';
import type { FolderType, OfferType } from '~/types/new/graphql';

export type TOfferTransformed = OfferType & { category_enum: string, external_id: number }
export const useCar = (offer: Offer | TOfferTransformed) => {

  function isNew(_offer = offer): _offer is TOfferTransformed {
    return false;
  }

  function isNewStock(_offer = offer) {
    return isNew(_offer) || (_offer.run <= 100 && _offer.owner.number <= 1);
  }
  return {
    isNewStock,
    isNew,
    getTitle(){
      if ('__is_folder__' in offer) return useFolder(offer as unknown as FolderType).getTitle();
      return isNew(offer)
        // eslint-disable-next-line max-len,vue/max-len
        ? `${offer.mark.title} ${offer.folder.title}, ${offer.modification.engine_volume} л, ${offer.modification.gearbox.title}, ${offer.complectation.title}`
        : `${offer.mark.title} ${offer.folder.title}, ${offer.year} года, ${offer.gearbox.title}`;
    },
    getTransformedOffer(): Offer | TOfferTransformed {
      if (!offer || !isNew(offer)) return offer;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const _offer: any = {
        __transformed__: true, ...offer as TOfferTransformed, ...offer.modification as object,
      } as unknown as TOfferTransformed;
      _offer.gearbox!.title_short_rus = _offer.gearbox!.title_short = _offer.gearbox!.title;
      _offer.driveType = _offer.drive_type;
      _offer.engineType = _offer.engine_type;
      _offer.bodyType = _offer.folder.bodyType;
      _offer.equipment_groups = _offer.complectation.equipment;
      _offer.__transformed__ = true;
      _offer.owner = { title: 'Новый' };
      return _offer;
    },
    getBadgeClass() {
      const predicates = [
        () => (isNewStock() || isNew()) && {
          class: 'stock stock--new',
          tooltipText: 'Автомобиль новый, в наличии в автоцентре.',
          text: 'Новый',
        },
      ];
      return predicates.map((i) => i()).find((i) => i) || {
        class: 'stock stock--true',
        tooltipText: 'В наличии в автоцентре.',
        text: 'В наличии',
      };
    },
    getCharacteristics() {
      if ('__is_folder__' in offer) return useFolder(offer as unknown as FolderType).getCharacteristics();
      return isNew(offer)
        ? offer.modification.tech_params
        : [
          {
            title: 'Год выпуска',
            value : offer.year,
            icon: 'icon-year',
          },
          {
            title: 'КПП',
            value : offer.gearbox.title_short_rus,
            icon: 'icon-kpp',
          },
          {
            title: 'Пробег',
            value: `${numberFormat(offer.run) || 0} км`,
            icon: 'icon-mileage',
          },
          {
            title: 'Объем двигателя',
            value : engineVolume(offer.engine_volume) + ' л',
            icon: 'icon-volume',
          },
          {
            title: 'Мощность двигателя',
            value : offer.engine_power + ' л.с.',
            icon: 'icon-engine',
          },
          {
            title: 'Тип двигателя',
            value : offer.engineType.title,
            icon: 'icon-fuel',
          },
          // {
          //   title: 'Тип кузова',
          //   value : offer.bodyType.title,
          //   icon: 'icon-minivan-car',
          // },
          {
            title: 'Привод',
            value : offer.driveType.title,
            icon: 'icon-wd',
          },
          {
            title: 'Цвет кузова',
            value : offer.color.title,
            icon: 'icon-colors',
          },
          {
            title: 'Владельцев',
            value : offer.owner.title,
            icon: 'icon-ownerss',
          },
        ];
    },
  };
};
