import type { Ref } from 'vue';
// @ts-expect-error import not found but exist
import type { Device } from '@nuxtjs/device/dist/runtime/types';
import type { SeoTemplateCrumbs as Crumb } from '~/types/graphql';
import type { SettingSite } from '~/store/settingsStore';
import { obfuscatedJSON } from '#nuxt-prepare';

export default defineNuxtPlugin({
  name: 'providers',
  parallel: true,
  enforce: 'post',
  setup() {
    const title = useState('title', () => null);
    const crumbs = useState('crumbs', () => null);
    const isLighthouse = useState('isLighthouse', () => false);

    const { public: { IS_OBFUSCATION_ENABLED } } = useRuntimeConfig();

    const obfuscated = IS_OBFUSCATION_ENABLED
      ? useState('obfuscated', () => obfuscatedJSON)
      : {
        value: new Proxy({}, { // заглушка, чтобы при выключенной рандомизации
          get(target, p: string): string { // возвращать оригинальный класс при использовании $obf
            return p;
          },
        }),
      };
    return {
      provide: {
        title,
        crumbs,
        isLighthouse,
        obf: obfuscated,
      },
    };
  },
});

interface IProviders {
  $title: Ref<string>
  $crumbs: Ref<Crumb[]>
  $isLighthouse: Ref<boolean>
  $device: Device
  $settings: SettingSite
  $obf: Ref<{
    [k: string]: string
  }>
}

declare module '#app' {
  interface NuxtApp extends IProviders {}
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties extends IProviders {}
}

declare module 'vue' {
  interface ComponentCustomProperties extends IProviders {}
}

export { };
