import { useUtm } from '#imports';
import { useRequestURL } from '#app';

const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'] as const;
// utm метки, которые будут проверяться на наличие в url

export type TUtm = Record<typeof utmKeys[number], string>;

export default defineNuxtPlugin({
  name: 'utm',
  parallel: true, // не блокирует выполнение остальных плагинов
  enforce: 'post',
  setup() {
    const { cookieKeys: { utmCookieKey } } = useAppConfig(); // получаем имя кукиса для utm
    const url = useRequestURL(); /* получаем текущий url, нужен для получения
     url параметров и чтобы не вызывать useRoute() */
    const query = (Object.fromEntries(url.searchParams)); // получаем query параметры
    const utmFromQuery = Object.fromEntries(
      Object.entries(query).filter(([key]) => utmKeys.some((utmKey) => utmKey === key)),
    ) as TUtm; // фильтруем из всех query параметров utm метки

    const utmCookie = useCookie<Partial<Record<typeof utmKeys[number], string>>>(utmCookieKey); /* получаем
     реактивные куки */

    const utmFromReferer = [
      {
        sources: ['google.ru', 'google.com'], // список рефереров, которые будут проверяться
        utm: { // список utm меток, которые будут сохраняться в стор
          utm_source: 'google',
          utm_campaign: 'organic',
          utm_medium: '',
        },
      },
      {
        sources: ['yandex.ru', 'ya.ru'],  // список рефереров, которые будут проверяться
        utm: { // список utm меток, которые будут сохраняться в стор
          utm_source: 'yandex',
          utm_campaign: 'organic',
          utm_medium: '',
        },
      },
      {
        sources: ['mail.ru', 'go.mail.ru'],// список рефереров, которые будут проверяться
        utm: { // список utm меток, которые будут сохраняться в стор
          utm_source: 'mail',
          utm_campaign: 'organic',
          utm_medium: '',
        },
      },
    ];

    const referer = document.referrer; // получаем реферер, скорее всего можно также получать через useRequestHeaders()

    const utmFromRefererValue = referer
      ? utmFromReferer.find((i) => i.sources.some((k) => referer.includes(k)))?.utm
      : null; // проверяем реферер на соответствие списку рефереров, если нет нужных параметров в url - возвращаем null

    const utmFromSearchOrAd = Object.keys(utmFromQuery).length > 0 ? utmFromQuery : utmFromRefererValue; /*
    если в url есть рекламные параметры utm, то берем их, если нет - берем из реферера
    */

    if (!utmFromSearchOrAd) { // если в url нет utm параметров и
      // нет реферера, то сохраняем в стор все текущие куки, возможно сохранились старые рекламные utm
      // скорее всего не нужно сохранять прям все куки в стор, а только фильтровать utm-метки
      useUtm().value = utmCookie.value;
      return;
    }
    utmCookie.value = utmFromSearchOrAd; // записываем в куки все utm параметры из url или реферера
    useUtm().value = utmCookie.value; // сохраняем их в стор
  },
});
