import type { DeepNonNullable } from 'ts-essentials';
import { settings as settingsQuery } from '~/apollo/queries/settings';
import { request } from '~/utils/request';
import type { SettingsQuery, SettingsQueryVariables } from '~/types/graphql';

export interface SettingSite {
  counter_ym: string,
  counter_vk: string,
  contact_phone: string
  contact_coordinates: string
  contact_address: string
  contact_schedule: string
  legal_inn: string
  legal_kpp: string
  legal_name: string
  legal_ogrn: string
  legal_address: string
  ym: string
  city: string
  gtag: string
  email: string
  phone: string
  soc_ok: string
  soc_vk: string
  soc_livan: string
  goal_id: number
  in_city: string
  soc_zen: string
  vkpixel: string
  mytarget: number
  credit_sum: string
  soc_tiktok: string
  soc_youtube: string
  sale_credit: string
  vkpixel_alt: string
  dealer_title: string
  sale_tradein: string
  soc_telegram: string
  credit_period: string
  dealer_anchor: string
  soc_instagram: string
  credit_approve: string
  credit_percent: string
  max_sum_credit: string
  min_sum_credit: string
  credit_repayment: string
  first_installment: string
  max_period_credit: string
  min_period_credit: string
  credit_max_percent: string
  credit_registration: string
  work_schedule: string
  legal_address_index: string
  ya_maps_widget: string
  phone_top_otzovik: string
  phone1: string
  smart_captcha_client_key: string
  youtube_playlist_review: string
  [k: string]: string | number | undefined;
}

export const useSettingsStore = () => {
  const settings = useState<SettingSite>('settings', () => ref<SettingSite>({} as SettingSite));
  return {
    settings,
    async getSettings() {
      const { data } = await request<
        DeepNonNullable<SettingsQuery>, SettingsQueryVariables
      >(settingsQuery, {}, 'default');
      data.value.settings.settings.forEach((setting: SettingsQuery['settings']['settings'][number]) => {
        const { key } = setting;
        settings.value[key] = setting.value as SettingSite[typeof key];
      });
      return settings.value;
    },
  };
};
