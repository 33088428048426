type TSetSlug = 'catalog-filter';
export type TSetItem = {
  title: string;
  description?: string;
  slug: string;
  image?: string;
  slugName?: string;
  isFancy?: boolean;
  fancyDataSrc?: string
}
export type TSet<T extends string = TSetSlug> = {
  [key in T]: TSetItem[];
}
export const useSets = (type: TSetSlug | null = null) => {
  const sets: TSet = (
    {
      'catalog-filter': [
        {
          title: 'Седаны',
          slug: 'sedan',
          image: '/img/featured/featured-1.webp',
        },
        {
          title: 'Хэтчбек',
          slug: 'hatchback',
          image: '/img/featured/featured-2.webp',
        },
        {
          title: 'Для такси',
          slug: 'for-taxi',
          image: '/img/featured/featured-3.webp',
        },
        {
          title: 'Внедорожники',
          slug: 'allroad',
          image: '/img/featured/featured-4.webp',
        },
        {
          title: 'Премиум-класс',
          slug: 'premium',
          image: '/img/featured/featured-5.webp',
        },
        {
          title: 'Для нее',
          slug: 'for-woman',
          image: '/img/featured/featured-6.webp',
        },
      ],
    });

  const normilizeSlugs = (i: TSetItem) => {
    i.slugName = i.slug;
    i.slug = `set-${i.slug}`;
    return i;
  };

  return type ? sets[type].map(normilizeSlugs) : Object.values(sets).flat().map(normilizeSlugs);
};

// todo in app config
