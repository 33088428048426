import type { DocumentNode } from 'graphql/language';

export const foldersGql: DocumentNode = gql`query foldersNew($site_id: Int!, $except_id: Int, $popular: Boolean, $taxi: Boolean, $mark_slug:String, $sort: String, $page:Int, $limit:Int) {
    folders(site_id: $site_id,mark_slug:$mark_slug,sort:$sort,page: $page,limit: $limit, popular:$popular, taxi:$taxi, except_id: $except_id)
    {
        data{
            id
            title
            slug
            arrival
            availability
            min_price
            min_price_old
            engine_power
            taxi
            time
            modifications {
                id
                title
                engine_volume
            }
            mark{
                id
                title
                slug
                logo {
                    emblem_svg_xml
                    src
                }
            }
            image{
                image_medium
                image_medium_webp
                image_large
                image_large_webp
            }
            image_taxi{
                image_medium
                image_medium_webp
                image_large
                image_large_webp
            }
        }
    }
}`;
