export default defineNuxtPlugin({
  name: 'settings',
  parallel: true,
  enforce: 'post',
  async setup() {
    const { getSettings, settings } = useSettingsStore();
    await callOnce(async () => {
      const utm = useUtm();
      const settingsFromApi = await getSettings();
      const phoneOtzovik = settingsFromApi.phone_top_otzovik;

      const sourcePhoneMatcher = {
        'auto-otzovik': phoneOtzovik,
        'top-otzivov': phoneOtzovik,
        'autoreview-help': phoneOtzovik,
        'auto-review': phoneOtzovik,
      };

      const foundOtzovikNumber = Object.entries(sourcePhoneMatcher).find(([key]) => {
        return key === utm.value.utm_source;
      });
      if (foundOtzovikNumber && foundOtzovikNumber[1]) {
        settings.value = { ...settingsFromApi, phone: foundOtzovikNumber[1] };
      } else {
        settings.value = settingsFromApi;
      }
    });
    return {
      provide: {
        settings: settings.value,
      },
    };
  },
});
