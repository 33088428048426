export const useCategory = (...category: string[]) => ({
  categoryNew: '',
  categoryNewStock: '',
  isNew: () => false,
  isNewCatalog: () => false,
  transform() {
    return category.length === 1 ? category[0] : category;
  },
  findByName() {
    const { categories } = useAppConfig();
    const transformed = Object.values(categories).filter((i) => category.includes(i.route));
    return category.length === 1 ? transformed[0] : transformed;
  },
});
