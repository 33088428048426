import type { ComplectationType, FolderType, ModificationType } from '~/types/new/graphql';

type TUseFolderArgs = {
  initialModificationId?: number | string | null,
  initialComplectationId?: number | string | null,
}

export const useFolder = (
  folder: FolderType,
  {
    initialModificationId = null,
    initialComplectationId = null,
  }: TUseFolderArgs = {},
) => {
  const modification = useState<ModificationType>(`folder${folder.id}Modification`, () => {
    const foundModificationById = initialModificationId && folder.modifications.find(
      (modification) => modification.id === +initialModificationId,
    );
    return foundModificationById ? foundModificationById : folder.modifications[0];
  });
  const complectation = useState<ComplectationType>(`folder${folder.id}Complectation`, () => {
    // !! MIGHT BE EMPTY CUZ MINI CARD DOESN'T HAVE IT BUT THIS SHIT NEED IN MAIN FOLDER CARD !!
    const foundComplectationById = initialComplectationId && modification.value.complectations.find(
      (complectation) => complectation.id === +initialComplectationId,
    );

    return foundComplectationById ? foundComplectationById: modification.value.complectations?.[0];
  });
  const isFolder = (_folder: object = folder): _folder is FolderType => {
    return isFolder(_folder);
  };

  return {
    modification,
    complectation,
    isFolder,
    getTechLabel($modification: ModificationType = modification.value){
      // eslint-disable-next-line max-len,vue/max-len
      return `${$modification.engine_type.title} ${$modification.engine_power} л.с ${$modification.gearbox.title} ${$modification.drive_type.title}`;
    },
    getTitle({
      complectation: $complectation = complectation.value,
      modification: $modification = modification.value,
    } = {}) {
      // eslint-disable-next-line max-len,vue/max-len
      return `${folder.mark.title} ${folder.title}, ${engineVolume($modification.engine_volume)} л, ${$complectation.title}`;
    },
    getCharacteristics(){
      return modification.value.tech_params;
    },
    shortTitle: `${folder.mark.title} ${folder.title}`,
    getTechs({ modification: $modification = modification.value } = {}) {
      const zeroHundred = $modification.tech_params.find((p) => p.title === 'Разгон от 0 до 100 км/ч')?.value;

      return {
        engineVolume: {
          title: 'объем двигателя',
          slug: 'engine_volume',
          value: `${engineVolume(modification.value.engine_volume)} л`,
          icon: 'icon-volume',
        },
        zeroToHundred: zeroHundred ? {
          title: '0-100 км/ч',
          slug: 'zero_to_hundred',
          value: `${zeroHundred}`,
          icon: 'icon-mileage',
        } : null,
        engineType: {
          title: 'тип двигателя',
          slug: 'engine_type',
          value: modification.value.engine_type.title,
          icon: 'icon-fuel',
        },
        enginePower: {
          title: 'мощность двигателя',
          slug: 'engine_power',
          value: `${modification.value.engine_power} л.с.`,
          icon: 'icon-engine',
        },
        bodyType: {
          title: 'тип кузова',
          slug: 'body_type',
          value: folder.bodyType.title,
          icon: 'icon-form',
        },
        driveType: {
          title: 'привод',
          slug: 'drive_type',
          value: modification.value.drive_type.title,
          icon: 'icon-wd',
        },
      };
    },
  };
};
