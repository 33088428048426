import type { DocumentNode } from 'graphql/language';

export const folderGql: DocumentNode = gql`query folder($site_id: Int!, $mark_slug:String, $slug:String) {
    folder(site_id: $site_id,mark_slug:$mark_slug,slug:$slug)
    {
        id
        title
        title_cyrillic
        slug
        arrival
        availability
        min_price
        min_price_old
        time
        fuel
        engine_power
        engine_power_kvt
        max_speed
        body_length
        body_height
        body_width
        wheelbase
        clearance
        distance_reserve
#        dealerSite {
#            address
#            city
#            coordinates
#            description
#            id
#            logo_src
#            metro
#            schedule
#            phone
#            short_description
#            site
#            slug
#            title
#            images {
#                medium
#                medium_webp
#                original
#                small
#                small_webp
#            }
#        }
        mark{
            id
            title
            title_cyrillic
            warranty_year
            warranty_km
            slug
            kind_enum
            logo {
                emblem_svg_xml
            }
        }
        bodyType{
            id
            name
            title
        }
        modifications{
            id
            title
            gearbox{
                name
                title
            }
            drive_type{
                name
                title
            }
            engine_type{
                name
                title
            }
            engine_volume
            engine_power
            engine_power_kvt
            tech_params{
                title
                value
            }
            complectations(site_id: $site_id) {
                id
                title
                equipment {
                    title
                    values
                }
                price
                price_old
            }
        }
        colors{
            id
            slug
            title
            taxi
            hex_code
            roof_hex_code
            is_white
            image{
                medium
                medium_webp
                large
                large_webp
                original
            }
        }
        image{
            image_tiny
            image_tiny_webp
            image_small
            image_small_webp
            image_medium
            image_medium_webp
            image_large
            image_large_webp
            original
        }
        images{
            medium
            medium_webp
            large
            large_webp
            original
        }
        interior_images{
            medium
            medium_webp
            large
            large_webp
            original
        }
        exterior_images{
            medium
            medium_webp
            large
            large_webp
            original
        }
    }
}`;
