// Generated by nuxt-prepare
export const seo = {
  "seoTemplate": [
    {
      "name": "category-mark-folder-offer",
      "category": "new",
      "h1": "{{ offer.mark.title }} {{ offer.folder.title }} {{ #offer.complectation }} {{ offer.complectation.title }} {{ /offer.complectation }}",
      "title": "Купить новый {{ offer.mark.title }} {{ offer.folder.title }} {{ #offer.complectation }}{{ offer.complectation.title }}{{ /offer.complectation }}, ID {{ offer.external_id }} в Санкт-Петербурге в автосалоне «Авалон»",
      "description": "Новый {{ offer.mark.title }} {{ offer.folder.title }} дешево, за {{#numberFormat}}{{ offer.price }}{{/numberFormat}} руб. в {{ city.pred }}, ID {{ offer.external_id }} в Санкт-Петербурге в автосалоне «Авалон»",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Новые автомобили",
          "link": "/cars-new"
        },
        {
          "title": "{{ offer.mark.title }}",
          "link": "/cars-new/{{ offer.mark.slug }}"
        },
        {
          "title": "{{ offer.folder.title }}",
          "link": "/cars-new/{{ offer.mark.slug }}/{{ offer.folder.slug }}"
        },
        {
          "title": "{{ offer.mark.title }} {{ offer.folder.title }}",
          "link": ""
        }
      ]
    },
    {
      "name": "category-mark",
      "category": "cars-new",
      "h1": "Купить новые {{ mark.title }} {{ folder.title }} в Санкт-Петербурге",
      "title": "{{ mark.title }} купить с новые в Санкт-Петербурге в кредит. Автокредит на новое авто от 4.9% без взноса!",
      "description": "Купить новые {{ mark.title }} в Санкт-Петербурге | Лучшие цены на {{ mark.title }} в кредит, рассрочку.",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Новые автомобили",
          "link": "/cars-new"
        },
        {
          "title": "{{ mark.title }}",
          "link": ""
        }
      ]
    },
    {
      "name": "category-mark-folder",
      "category": "cars-new",
      "h1": "Купить новые {{ mark.title }} {{ folder.title }} в Санкт-Петербурге",
      "title": "{{ mark.title }} {{ folder.title }} купить с новые в Санкт-Петербурге в кредит. Автокредит на новое авто от 4.9% без взноса!",
      "description": "Купить новые {{ mark.title }} {{ folder.tile }} в Санкт-Петербурге | Лучшие цены на {{ mark.title }} {{ folder.title }} в кредит, рассрочку.",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Новые автомобили",
          "link": "/cars-new"
        },
        {
          "title": "{{ mark.title }}",
          "link": "/cars-new/{{ mark.slug }}"
        },
        {
          "title": "{{ folder.title }}",
          "link": ""
        }
      ]
    },
    {
      "name": "new-mark",
      "category": "new",
      "h1": "Новые {{ mark.title }} в наличии в Санкт-Петербурге",
      "title": "Новые автомобили {{ mark.title }} в автосалоне «Авалон». Приобрести новый {{ mark.title }} в наличии в кредит",
      "description": "Широкий ассортимент новых автомобилей {{mark.title}} доступен в автосалоне «Авалон». Ознакомьтесь с модельным рядом, ценами и специальными предложениями на сайте. Лучшие предложения по продаже новых {{mark.title}} в Санкт-Петербурге!",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Новые автомобили",
          "link": "/cars-new"
        },
        {
          "title": "{{ mark.title }}",
          "link": ""
        }
      ]
    },
    {
      "name": "new-mark-folder",
      "category": "new",
      "h1": "{{ #isFemaleKind }} Новая {{ /isFemaleKind }} {{ ^isFemaleKind }} Новый {{ /isFemaleKind }} {{ mark.title }} {{ folder.title }} в наличии",
      "title": "Купить новый {{mark.title}} {{folder.title}} в автосалоне «Авалон» | Цены, фото, характеристики. Покупка {{mark.title}} {{folder.title}} в Санкт-Петербурге в кредит, рассрочку, trade-in",
      "description": "Лучшие предложения новых {{mark.title}} {{folder.title}} в автосалоне «Авалон». Получите полную информацию о ценах, характеристиках, фотографиях и условиях покупки в кредит.",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Новые автомобили",
          "link": "/cars-new"
        },
        {
          "title": "{{ mark.title }}",
          "link": "/new/{{ mark.slug }}"
        },
        {
          "title": "{{ folder.title }}",
          "link": ""
        }
      ]
    },
    {
      "name": "category",
      "category": "cars-new",
      "h1": "Новые автомобили в «Авалон»",
      "title": "Каталог новых автомобилей в наличии — «Авалон» в Санкт-Петербурге",
      "description": "В каталоге «Авалон» вы найдете широкий выбор новых автомобилей от ведущих мировых производителей. Посетите наш автосалон и выберите идеальный автомобиль для себя!",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Новые автомобили",
          "link": ""
        }
      ]
    },
    {
      "name": "category-set-setname",
      "category": "cars",
      "h1": "{{ set.title }} с пробегом",
      "title": "Купить авто с пробегом {{ set.title }} в Санкт-Петербурге — автоцентр «Авалон»",
      "description": "Купить авто с пробегом {{ set.title }} в Санкт-Петербурге — автоцентр «Авалон». Проверенные авто с пробегом в кредит у официального дилера «Авалон». Продажа подержанных автомобилей отечественных и зарубежных брендов в автосалоне в Санкт-Петербурге.",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Авто с пробегом",
          "link": "/cars"
        },
        {
          "title": "{{ set.title }}",
          "link": ""
        }
      ]
    },
    {
      "name": "stocks",
      "category": null,
      "h1": "Акции",
      "title": "Адрес автосалона официального дилера в Санкт-Петербурге – «Авалон»",
      "description": "Контакты. Официальный дилер «Авалон» – продажа подержанных автомобилей отечественных и зарубежных брендов.",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Акции",
          "link": ""
        }
      ]
    },
    {
      "name": "category",
      "category": "cars",
      "h1": "Автомобили с пробегом",
      "title": "Продажа автомобилей с пробегом (б/у) в Санкт-Петербурге, купля-продажа подержанных авто –«Авалон»",
      "description": "Проверенные автомобили с пробегом в кредит в Санкт-Петербурге. Автосалон «Авалон» предлагает в продажу подержанные автомобилей отечественных и зарубежных брендов!",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Авто с пробегом",
          "link": ""
        }
      ]
    },
    {
      "name": "category-mark",
      "category": "cars",
      "h1": "Купить {{ mark.title }} <span>с пробегом в кредит</span>",
      "title": "Купить с пробегом {{ mark.title }} в Санкт-Петербурге в автосалоне - «Авалон»",
      "description": "Купить с пробегом {{ mark.title }} в Санкт-Петербурге в автосалоне - «Авалон». Проверенные авто с пробегом в кредит у официального дилера «Авалон». Продажа подержанных автомобилей отечественных и зарубежных брендов в автосалоне в Санкт-Петербурге.",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Авто с пробегом",
          "link": "/cars"
        },
        {
          "title": "{{ mark.title }}",
          "link": ""
        }
      ]
    },
    {
      "name": "category-mark-folder",
      "category": "cars",
      "h1": "Купить {{ mark.title }} {{ folder.title }} <span>с пробегом в кредит</span>",
      "title": "Купить с пробегом {{ folder.mark.title }} {{ folder.title }} в Санкт-Петербурге в автосалоне - «Авалон»",
      "description": "Купить с пробегом {{ folder.mark.title }} {{ folder.title }} в Санкт-Петербурге в автосалоне - «Авалон». Проверенные авто с пробегом в кредит у официального дилера «Авалон». Продажа подержанных автомобилей отечественных и зарубежных брендов в автосалоне в Санкт-Петербурге.",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Авто с пробегом",
          "link": "/cars"
        },
        {
          "title": "{{ mark.title }}",
          "link": "/cars/{{ mark.slug }}"
        },
        {
          "title": "{{ folder.title }}",
          "link": ""
        }
      ]
    },
    {
      "name": "category-mark-folder-generation",
      "category": "cars",
      "h1": "Купить {{ mark.title }} {{ folder.title }} {{ generation.title }} <span>с пробегом в кредит</span>",
      "title": "{{ mark.title_rus }} {{ folder.title_rus }} {{ generation.title }} в Санкт-Петербурге в автосалоне - «Авалон»",
      "description": "Купить с пробегом {{ folder.mark.title }} {{ folder.title }} {{ generation.title }} в Санкт-Петербурге в автосалоне - «Авалон». Проверенные авто с пробегом в кредит у официального дилера «Авалон». Продажа подержанных автомобилей отечественных и зарубежных брендов в автосалоне в Санкт-Петербурге.",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Авто с пробегом",
          "link": "/cars"
        },
        {
          "title": "{{ mark.title }}",
          "link": "/cars/{{ mark.slug }}"
        },
        {
          "title": "{{ folder.title }}",
          "link": "/cars/{{ mark.slug }}/{{ folder.slug }}"
        },
        {
          "title": "{{ generation.title }}",
          "link": ""
        }
      ]
    },
    {
      "name": "category-mark-folder-offer",
      "category": "cars",
      "h1": "{{ offer.mark.title }} {{ offer.folder.title }} {{#engineVolume}}{{offer.engine_volume}}{{/engineVolume}} {{ offer.gearbox.title_short_rus }}, {{ offer.year }}",
      "title": "Купить с пробегом {{ offer.mark.title }} {{ offer.folder.title }}, {{ offer.year }} с пробегом {{#numberFormat}}{{offer.run}}{{/numberFormat}} км. в Санкт-Петербурге ({{ offer.external_id }}) - «Авалон»",
      "description": "{{offer.mark.title}} {{offer.folder.title}} {{#engineVolume}}{{offer.engine_volume}}{{/engineVolume}} {{offer.gearbox.title_short_rus}}, {{offer.year}}. Купить {{offer.mark.title}} {{offer.folder.title}}, с пробегом {{#numberFormat}}{{offer.run}}{{/numberFormat}} км. в кредит в Санкт-Петербурге, в автосалоне «Авалон». №{{offer.external_id}}",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Авто с пробегом",
          "link": "/cars"
        },
        {
          "title": "{{ offer.mark.title }}",
          "link": "/cars/{{ mark.slug }}"
        },
        {
          "title": "{{ offer.folder.title }}",
          "link": "/cars/{{ offer.mark.slug }}/{{ offer.folder.slug }}"
        },
        {
          "title": "{{ offer.mark.title }} {{ offer.folder.title }}",
          "link": "/cars/{{ offer.mark.slug }}/{{ offer.folder.slug }}/{{ offer.external_id }}"
        }
      ]
    },
    {
      "name": "credit-bank",
      "category": null,
      "h1": "{{ bank.title }}",
      "title": "Онлайн заявка на автокредит в банк «{{ bank.name }}» в Санкт-Петербурге — «Авалон»",
      "description": "Онлайн заявка на {{ bank.title }} в Санкт-Петербурге в автосалоне «Авалон». Огромный выбор б/у автомобилей, ежедневные обновления, подарки",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Автокредит",
          "link": "/credit"
        },
        {
          "title": "{{ bank.name }}",
          "link": ""
        }
      ]
    },
    {
      "name": "thanks",
      "category": null,
      "h1": "Заявка успешно отправлена",
      "title": "Заявка успешно отправлена — «Авалон»",
      "description": "Спасибо! Заявка успешно отправлена в автосалон «Авалон».",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Заявка успешно отправлена",
          "link": ""
        }
      ]
    },
    {
      "name": "index",
      "category": null,
      "h1": "Продажа автомобилей с пробегом в Санкт-Петербурге",
      "title": "Купить БУ Авто, объявления о продаже автомобилей с пробегом в Санкт-Петербурге | в автосалоне «Авалон»",
      "description": "Автокредит по 2 документам, одобрение 95%, оформление за 30 минут. Более 800 подержанных автомобилей в Санкт-Петербурге в наличии на нашей площадке.",
      "crumbs": null
    },
    {
      "name": "about",
      "category": null,
      "h1": "О компании",
      "title": "О компании автосалон «Авалон»",
      "description": "О компании автосалон «Авалон». Более 800 подержанных автомобилей в Санкт-Петербурге в наличии на нашей площадке.",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Об автоцентре",
          "link": ""
        }
      ]
    },
    {
      "name": "contacts",
      "category": null,
      "h1": "Контакты",
      "title": "Адрес автосалона официального дилера в Санкт-Петербурге – «Авалон»",
      "description": "Контакты. Официальный дилер «Авалон» – продажа подержанных автомобилей отечественных и зарубежных брендов.",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Контакты",
          "link": ""
        }
      ]
    },
    {
      "name": "privacy",
      "category": null,
      "h1": "Политика конфиденциальности персональных данных",
      "title": "Политика конфиденциальности и защиты личной информации",
      "description": "Политика конфиденциальности и защиты личной информации. Более 800 подержанных автомобилей в Санкт-Петербурге в наличии на нашей площадке.",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Политика конфиденциальности",
          "link": ""
        }
      ]
    },
    {
      "name": "favorites",
      "category": null,
      "h1": "Избранное",
      "title": "Список избранных автомобилей в «Авалон»",
      "description": "Избранное. Проверенные авто с пробегом в кредит у официального дилера «Авалон». Продажа подержанных автомобилей отечественных и зарубежных брендов в автосалоне в Санкт-Петербурге.",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Избранное",
          "link": ""
        }
      ]
    },
    {
      "name": "warranty",
      "category": null,
      "h1": "Гарантия",
      "title": "Гарантия от автосалона «Авалон»",
      "description": "Гарантия.«Авалон» предлагает огромный выбор подержанных автомобилей с пробегом в Санкт-Петербурге в кредит. Мы являемся официальным дилером в сегменте подержанных б/у авто.",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Гарантия",
          "link": ""
        }
      ]
    },
    {
      "name": "reviews",
      "category": null,
      "h1": "Отзывы об автоцентре «Авалон»",
      "title": "Отзывы наших покупателей – «Авалон»",
      "description": "Отзывы об автосалоне «Авалон». Официальный дилер «Авалон» – продажа подержанных автомобилей отечественных и зарубежных брендов.",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Отзывы",
          "link": ""
        }
      ]
    },
    {
      "name": "credit",
      "category": null,
      "h1": "Подать заявку на автокредит онлайн",
      "title": "Автокредит на авто с пробегом в Санкт-Петербурге в автосалоне на Варшавке - «Авалон»",
      "description": "Оформление автомобиля в кредит в Санкт-Петербурге. Одобрение 90%, за 30 минут. Выкуп, Trade-In. «Авалон» предлагает огромный выбор бу авто в Санкт-Петербурге в кредит.",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Автокредит",
          "link": ""
        }
      ]
    },
    {
      "name": "installments",
      "category": null,
      "h1": "Рассрочка на авто с пробегом",
      "title": "Подать заявку на рассрочку - «Авалон»",
      "description": "Оставьте заявку на рассрочку онлайн прямо сейчас, одобрение 90%, решение за 30 минут!  Автосалон «Авалон» – продажа подержанных автомобилей отечественных и зарубежных брендов.",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Рассрочка",
          "link": ""
        }
      ]
    },
    {
      "name": "exchange",
      "category": null,
      "h1": "Подать заявку на Trade-In онлайн",
      "title": "Обмен машины на машину в Санкт-Петербурге, с доплатой, ключ в ключ Trade-In б/у — «Авалон»",
      "description": "Обмен авто в Санкт-Петербурге с доплатой по программе Trade-In. Автосалон «Авалон» – продажа подержанных автомобилей отечественных и зарубежных брендов.",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Trade-In",
          "link": ""
        }
      ]
    },
    {
      "name": "buyout",
      "category": null,
      "h1": "Срочный выкуп авто в Санкт-Петербурге",
      "title": "Срочный выкуп авто в Санкт-Петербурге с выгодными условиями - «Авалон»",
      "description": "Срочно выкупим ваш автомобиль по рыночной цене в Санкт-Петербурге. Быстро и дорого продать авто с пробегом на сайте «Авалон». Мы являемся официальным дилером в сегменте подержанных б/у авто.",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Выкуп",
          "link": ""
        }
      ]
    },
    {
      "name": "banks",
      "category": null,
      "h1": "Банки-партнеры",
      "title": "Банки-партнеры - «Авалон»",
      "description": "Банки-партнеры, сотрудничество автосалона «Авалон». Автосалон предлагает в продажу подержанные автомобилей отечественных и зарубежных брендов!",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Банки-партнеры",
          "link": ""
        }
      ]
    },
    {
      "name": "error",
      "category": null,
      "h1": "Ошибка 404 — страница не найдена",
      "title": "Ошибка 404 — страница не найдена",
      "description": "Ошибка 404 — страница не найдена",
      "crumbs": [
        {
          "title": "Главная",
          "link": "/"
        },
        {
          "title": "Ошибка 404",
          "link": ""
        }
      ]
    }
  ]
}
export type Seo = typeof seo
export const banks = {
  "sberbank": {
    "id": 1,
    "name": "Сбербанк",
    "slug": "sberbank",
    "request": 367,
    "approval": 184,
    "rating": 91.75,
    "rate": 13,
    "image": "https://api-used.ru/images/banks/10e7/8927e72e2/logo-sber.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/5de1/73af5936d/logo-sber-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/8ae0/1527d0dde/sberbank-small_webp.webp"
    },
    "title": "Автокредит в Сбербанке",
    "isThemeDependent": false
  },
  "gazprombank-avtolizing": {
    "id": 35,
    "name": "Газпромбанк Автолизинг",
    "slug": "gazprombank-avtolizing",
    "request": 120,
    "approval": 97,
    "rating": 89.2,
    "rate": 14,
    "image": "https://api-used.ru/images/banks/e19b/40fe33808/gazprom-lizing.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/6d43/decd25e33/gazprom-lizing-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/178c/a6536b615/gazprombank-small_webp.webp"
    },
    "title": "Газпромбанк Автолизинг",
    "isThemeDependent": false
  },
  "alfa-bank": {
    "id": 2,
    "name": "Альфа-Банк",
    "slug": "alfa-bank",
    "request": 325,
    "approval": 169,
    "rating": 87.88,
    "rate": 4.9,
    "image": "https://api-used.ru/images/banks/7eec/d79e84714/logo-alfa.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/20c1/3c7570639/logo-alfa-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/0d87/cb224e52f/alfa-bank-small_webp.webp"
    },
    "title": "Автокредит в Альфа банке",
    "isThemeDependent": false
  },
  "atb-bank": {
    "id": 36,
    "name": "АТБ Банк",
    "slug": "atb-bank",
    "request": 300,
    "approval": 170,
    "rating": 86.76,
    "rate": 4.4,
    "image": "https://api-used.ru/images/banks/a7ba/4f82f0e04/logo-atb.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/b799/7e19fefc7/logo-atb-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/be78/961066d7b/atb-bank-small_webp.webp"
    },
    "title": "Автокредит в АТБ банке",
    "isThemeDependent": false
  },
  "drive-click-bank": {
    "id": 31,
    "name": "Драйв Клик Банк",
    "slug": "drive-click-bank",
    "request": 325,
    "approval": 175,
    "rating": 82.77,
    "rate": 4.9,
    "image": "https://api-used.ru/images/banks/6ae9/bd14fc800/drive-click.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/15bb/3a38deb6a/drive-click-inverted.svg",
    "image_car": null,
    "title": "Автокредит в Драйв Клик Банке",
    "isThemeDependent": false
  },
  "vtb": {
    "id": 4,
    "name": "ВТБ",
    "slug": "vtb",
    "request": 290,
    "approval": 151,
    "rating": 78.42,
    "rate": 11.9,
    "image": "https://api-used.ru/images/banks/fdd6/0cfdc0bcb/vtb.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/d093/da4f61c97/vtb-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/4d57/f0abc2262/vtb-small_webp.webp"
    },
    "title": "Автокредит в ВТБ банке",
    "isThemeDependent": false
  },
  "gazprombank": {
    "id": 12,
    "name": "Газпромбанк",
    "slug": "gazprombank",
    "request": 277,
    "approval": 124,
    "rating": 76.25,
    "rate": 4.9,
    "image": "https://api-used.ru/images/banks/cb23/f39341c98/Asset-1.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/7d22/a142c9100/gazprombank-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/3bf4/6b86733f9/gazprombank-small_webp.webp"
    },
    "title": "Автокредит в Газпромбанке",
    "isThemeDependent": false
  },
  "zenit-bank": {
    "id": 34,
    "name": "Зенит Банк",
    "slug": "zenit-bank",
    "request": 322,
    "approval": 160,
    "rating": 75.8,
    "rate": 15.8,
    "image": "https://api-used.ru/images/banks/e1b2/2835f27d8/bank-zenit.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/ceee/00296ddaa/bank-zenit-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/8158/a9be52aa1/zenit-small_webp.webp"
    },
    "title": "Автокредит в Зенит Банке",
    "isThemeDependent": false
  },
  "bank-ingosstrah": {
    "id": 5,
    "name": "Ингосстрах Банк",
    "slug": "bank-ingosstrah",
    "request": 301,
    "approval": 151,
    "rating": 75.25,
    "rate": 11.7,
    "image": "https://api-used.ru/images/banks/2a0a/6c38463f9/logo.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/26e7/f8e30a533/ingostrah-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/82e8/2a0895b29/ingosstrah-bank-small_webp.webp"
    },
    "title": "Автокредит в Ингосстрах Банке",
    "isThemeDependent": false
  },
  "raiffeisen-bank": {
    "id": 6,
    "name": "Райффайзен Банк",
    "slug": "raiffeisen-bank",
    "request": 240,
    "approval": 132,
    "rating": 72.6,
    "rate": 10.99,
    "image": "https://api-used.ru/images/banks/2bf7/266ec01f3/logo.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/6206/4d0775f61/logo_inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/185a/d829847cf/raiffeisen-bank-small_webp.webp"
    },
    "title": "Автокредит в Райффайзенбанке",
    "isThemeDependent": true
  },
  "otkrytie-bank": {
    "id": 7,
    "name": "Банк Открытие",
    "slug": "otkrytie-bank",
    "request": 272,
    "approval": 138,
    "rating": 70.75,
    "rate": 11.9,
    "image": "https://api-used.ru/images/banks/cc53/3901a130a/Group-111.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/d89c/4b35cc9a5/bank-otkritie-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/ff35/b80368307/otkrytie-bank-small_webp.webp"
    },
    "title": "Автокредит в Банке Открытие",
    "isThemeDependent": false
  },
  "bank-ekspobank": {
    "id": 8,
    "name": "Экспобанк",
    "slug": "bank-ekspobank",
    "request": 175,
    "approval": 110,
    "rating": 69.46,
    "rate": 7.9,
    "image": "https://api-used.ru/images/banks/1c0c/8399d7a74/logo-expo.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/767e/b16d1b3af/logo-expo-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/b8d7/5e70f44a6/bank-ekspobank-small_webp.webp"
    },
    "title": "Автокредит в Экспобанке",
    "isThemeDependent": false
  },
  "primsocbank": {
    "id": 37,
    "name": "Примсоцбанк",
    "slug": "primsocbank",
    "request": 123,
    "approval": 105,
    "rating": 68.8,
    "rate": 21.5,
    "image": "https://api-used.ru/images/banks/7513/5c441b2e2/primsoc-logo.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/1ad3/468932d57/primsoc-logo-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/0534/6584a1480/bank-small_webp.webp"
    },
    "title": "Автокредит в Примсоцбанке",
    "isThemeDependent": false
  },
  "avtokredit-v-banke-oranzevyi": {
    "id": 38,
    "name": "Банк Оранжевый",
    "slug": "avtokredit-v-banke-oranzevyi",
    "request": 167,
    "approval": 105,
    "rating": 67.3,
    "rate": 20.5,
    "image": "https://api-used.ru/images/banks/2e29/ad1750cc4/bank-oranzhevyy.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/23db/27553b233/bank-oranzhevyy-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/aeda/9819cc7f4/bank-orange-small_webp.webp"
    },
    "title": "Автокредит в банке Оранжевый",
    "isThemeDependent": false
  },
  "bank-absolut": {
    "id": 9,
    "name": "Абсолют Банк",
    "slug": "bank-absolut",
    "request": 167,
    "approval": 105,
    "rating": 66.28,
    "rate": 21.5,
    "image": "https://api-used.ru/images/banks/de57/89be83de5/Group-23836.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/caa9/a746ffc7e/absolut-bank.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/f5c3/8fdcd74cb/bank-absolut-small_webp.webp"
    },
    "title": "Автокредит в Абсолют Банке",
    "isThemeDependent": false
  },
  "bank-mkb": {
    "id": 10,
    "name": "МКБ",
    "slug": "bank-mkb",
    "request": 178,
    "approval": 107,
    "rating": 64.08,
    "rate": 14.5,
    "image": "https://api-used.ru/images/banks/e834/ee301f437/mkb.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/4469/84a26f5d9/mkb-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/9ee7/e9c15cb46/bank-mkb-small_webp.webp"
    },
    "title": "Автокредит в банке МКБ",
    "isThemeDependent": false
  },
  "bank-avangard": {
    "id": 11,
    "name": "Банк Авангард",
    "slug": "bank-avangard",
    "request": 331,
    "approval": 142,
    "rating": 61.2,
    "rate": 11,
    "image": "https://api-used.ru/images/banks/8656/596e4b171/bank-avangard.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/821b/3cef5abcb/bank-avangard-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/a846/f8b8306f9/bank-avangard-small_webp.webp"
    },
    "title": "Автокредит в Банке Авангард",
    "isThemeDependent": false
  },
  "bank-otp": {
    "id": 14,
    "name": "ОТП БАНК",
    "slug": "bank-otp",
    "request": 97,
    "approval": 65,
    "rating": 43.54,
    "rate": 13.99,
    "image": "https://api-used.ru/images/banks/04c5/aa5e65dec/logo-otp.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/a046/ef9a1f76b/logo-otp-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/afa7/8e13f1d6b/bank-otp-small_webp.webp"
    },
    "title": "Автокредит в ОТП Банке",
    "isThemeDependent": false
  },
  "pochta-bank": {
    "id": 16,
    "name": "Почта Банк",
    "slug": "pochta-bank",
    "request": 149,
    "approval": 69,
    "rating": 31.53,
    "rate": 16.9,
    "image": "https://api-used.ru/images/banks/19e1/4e31ccb03/pochta-bank.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/681b/21345171b/pochta-bank-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/80cb/9676e78c9/pochta-bank-small_webp.webp"
    },
    "title": "Автокредит в Почта Банке",
    "isThemeDependent": false
  },
  "bank-renessanse": {
    "id": 18,
    "name": "Ренессанс Банк",
    "slug": "bank-renessanse",
    "request": 114,
    "approval": 51,
    "rating": 23.09,
    "rate": 9.9,
    "image": "https://api-used.ru/images/banks/c895/6a151341d/logo-renessans.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/32e7/d729fae46/logo-renessans-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/b339/121d6a7cb/bank-renessanse-small_webp.webp"
    },
    "title": "Автокредит в Ренессанс банке",
    "isThemeDependent": false
  },
  "loko-bank": {
    "id": 21,
    "name": "Локо-Банк",
    "slug": "loko-bank",
    "request": 188,
    "approval": 60,
    "rating": 19.25,
    "rate": 12.4,
    "image": "https://api-used.ru/images/banks/d821/13e3a5dfb/loko-bank.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/9fb8/6c768d5f8/loko-bank-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/2001/13b990425/loko-bank-small_webp.webp"
    },
    "title": "Автокредит в Локо Банке",
    "isThemeDependent": false
  },
  "sovkombank": {
    "id": 22,
    "name": "Совкомбанк",
    "slug": "sovkombank",
    "request": 54,
    "approval": 31,
    "rating": 18.17,
    "rate": 14.9,
    "image": "https://api-used.ru/images/banks/4609/b13411f01/logo-sovcom.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/e089/894540174/logo-sovcom-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/6d7b/3c6ae50b6/sovkombank-small_webp.webp"
    },
    "title": "Автокредит в Совкомбанке",
    "isThemeDependent": false
  },
  "uralsib-bank": {
    "id": 26,
    "name": "Уралсиб Банк",
    "slug": "uralsib-bank",
    "request": 86,
    "approval": 34,
    "rating": 13.76,
    "rate": 10.9,
    "image": "https://api-used.ru/images/banks/f1b8/40d0e8b8b/Vector2.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/c81e/ae93ee47b/uralsib-bank-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/9ea1/9b147e701/uralsib-bank-small_webp.webp"
    },
    "title": "Автокредит в Уралсиб Банке",
    "isThemeDependent": false
  },
  "bank-houm-kredit": {
    "id": 29,
    "name": "Хоум Банк",
    "slug": "bank-houm-kredit",
    "request": 251,
    "approval": 53,
    "rating": 11.07,
    "rate": 11.3,
    "image": "https://api-used.ru/images/banks/9064/1331ff755/home-bank.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/f5ce/cf2bac9de/home-bank-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/04d9/d58b06ff1/bank-houm-kredit-small_webp.webp"
    },
    "title": "Автокредит в Хоум банке",
    "isThemeDependent": false
  },
  "bank-promsviaz": {
    "id": 30,
    "name": "Промсвязьбанк",
    "slug": "bank-promsviaz",
    "request": 75,
    "approval": 29,
    "rating": 10.83,
    "rate": 19.9,
    "image": "https://api-used.ru/images/banks/dcc8/39832d01c/bank-promsviaz.svg",
    "image_inverted": "https://api-used.ru/images/bank_logo_inverted/5783/115416fc6/bank-promsviaz-inverted.svg",
    "image_car": {
      "small_webp": "https://api-used.ru/images/banks_car/0408/4a3c9dcaa/bank-promsviaz-small_webp.webp"
    },
    "title": "Автокредит Промсвязьбанк",
    "isThemeDependent": false
  }
}
export type Banks = typeof banks
export const obfuscatedJSON = {
  "slider-target": "zycffye",
  "slider-base": "edzmecm",
  "slider-connects": "bvcgupu",
  "slider-connect": "ioldags",
  "slider-origin": "zgqduqt",
  "slider-txt-dir-rtl": "itrnq-t",
  "slider-horizontal": "tg-sufw",
  "slider-vertical": "_bakmcw",
  "slider-handle": "zcrymc-",
  "slider-touch-area": "hd_wban",
  "slider-state-tap": "xibggvz",
  "slider-state-drag": "az-advq",
  "slider-tooltip-drag": "-tabijy",
  "slider-tooltip": "azfwpxe",
  "slider-tooltip-focus": "rothmqm",
  "slider-active": "pxtngpv",
  "slider-tooltip-hidden": "ccxxalr",
  "slider-focused": "bnz-gow",
  "slider-draggable": "-xookvo",
  "slider-tooltip-top": "snucpwa",
  "slider-tooltip-bottom": "wbrihun",
  "slider-tooltip-left": "kvlo_hu",
  "slider-tooltip-right": "kmndvii",
  "container": "wfxucuc",
  "spinner": "qybthyi",
  "state-error": "h_hjrmj",
  "retry": "dlczspm",
  "tip": "nfomihe",
  "_icon-container": "yvhpfzo",
  "_icon-search-wrapper": "-fnenfh",
  "_icon-sprite-title": "ddesmpd",
  "_icon-search": "qncqy_w",
  "_icon-preview-wrapper": "n-fylzf",
  "_icon-preview": "kukhhkl",
  "_icon-card": "gxjpbeo",
  "_icon-name": "dpfqlmb",
  "_icon-name-input": "nddmiiz",
  "grecaptcha-badge": "tuwsksi",
  "body--modal": "qyg-atj",
  "visually-hidden": "ftfgo_h",
  "brazzers-daddy": "qct_ssa",
  "tmb-wrap": "kv-mdmr",
  "tmb-wrap-table": "f_hmxx_",
  "catalog__car-img": "_pcwlsi",
  "catalog__car-item--long": "-skpbil",
  "catalog__car-item--bank": "ysgeefc",
  "type_last": "nmrtits",
  "lt-ie9": "l-vrydr",
  "state_hover": "sizmyyi",
  "overlay": "uwtdhna",
  "grid--container": "_ux-xtm",
  "grid--car": "hplpxq_",
  "page-main__banks": "rvevdtc",
  "grid": "d-xbmrv",
  "grid--catalog": "jsflffl",
  "grid--footer": "ahzdvjs",
  "grid--featured": "pzaxiuv",
  "grid--reviews": "zhbyfeq",
  "grid--header": "-ixvlff",
  "grid__col-2": "feravmv",
  "grid__col-3": "-aomssx",
  "grid__col-4": "weeawog",
  "grid__col-5": "oyuit-g",
  "grid__col-6": "bukbjln",
  "grid__col-7": "rehsdjq",
  "grid__col-8": "uujazss",
  "grid__col-9": "_jptvti",
  "grid__col-10": "cibfbcx",
  "grid__col-11": "e-nuacr",
  "grid__col-12": "kqwrazi",
  "grid--application": "rnmt-pt",
  "grid--application-modal": "pneuhyt",
  "text__wrap--figure": "odzuduc",
  "featured__reviews": "dcwl-lw",
  "catalog__img": "lcae-aa",
  "folder__page": "rfvjmac",
  "folder__page-group": "dddtcqe",
  "folder__page-right": "lvlxcng",
  "folder__page-left": "zkel_lc",
  "folder__page-img-wrapper": "gvrpxjs",
  "folder__colors": "uxqipov",
  "colors__item": "urhxxx-",
  "folder__tech": "kodzqks",
  "folder__specifications": "vdmzikv",
  "folder__complectations": "nzqrweu",
  "folder__page-buy": "xxcen_u",
  "folder__page-buy-title": "gjotkue",
  "folder__page-prices": "npixlkc",
  "folder__page-price": "ezym-rx",
  "folder__page-price-credit": "t_biipo",
  "folder__page-buy-controls": "dkzfinp",
  "button": "etjsvje",
  "tech": "udceb-o",
  "tech__item": "igdylbc",
  "tech__item-icon-wrap": "mdg_ok-",
  "tech__item-icon": "edioioh",
  "tech__item--small": "mqfoa_c",
  "car__tech-content": "ntvoqj-",
  "hamburger": "g--gbxq",
  "hamburger__element": "knrynm-",
  "page-main__button-fixed": "jyexauf",
  "page-main__makes-count": "eygll--",
  "page-main__banks-main": "-_kgayi",
  "heading--h2": "nvtcs_b",
  "page-main__car": "vkrhjk-",
  "page-main__catalog": "mfbowiy",
  "page-main__makes-index": "exmqvxi",
  "page-main__promo": "rgiptms",
  "page-main__slider-index": "ondkhk-",
  "page-main__application": "dbczeoj",
  "benefits": "jcc_afv",
  "heading--h1": "tdytabc",
  "heading-group__wrap": "oe_-wkt",
  "page-main": "fqtswbn",
  "page-main__credit-index": "zgu_vbj",
  "banks__list": "ixsqn_c",
  "banks__img": "nwzkgih",
  "banks__item": "_b-lbdl",
  "tabs": "ouzikun",
  "catalog__list": "hnranby",
  "page-main__stories": "eooggzc",
  "page-main__filter": "zeplihe",
  "benefits__list": "lqhkrvf",
  "page-main__featured": "p-ryy-u",
  "page-main__video": "mivsfmm",
  "page-main__about": "kdcbuzv",
  "page-main__warranty": "yycccwd",
  "heading-group": "kkaopx_",
  "heading": "pxumpgo",
  "video": "ekxucyf",
  "makes": "dpyfsrg",
  "page-header": "bcguhoj",
  "page-header__logo-img": "klxdvpw",
  "page-header__buttons-link--phone": "pdwokad",
  "page-header__logo-block": "vrwurkx",
  "page-header--search": "_tmgzyv",
  "page-header__buttons": "_jsii_u",
  "page-header__container": "njdwztw",
  "page-header__wrap": "oegodph",
  "page-header__buttons-link": "nqfzzzb",
  "page-header__makes-list": "ryxxvey",
  "page-header__logo-circle": "nuabwop",
  "page-header__makes-list--full": "rnjslty",
  "page-header__makes": "upeb-nz",
  "page-header__makes-more-arrow": "_rckfuq",
  "page-header__makes-more--active": "zaxaqve",
  "page-header__makes-more": "-upgzqr",
  "page-header__nav": "gkerhml",
  "page-header__back": "aanysj-",
  "page-header__back-model": "ccfdrbh",
  "page-header__back-arrow": "chcqu_t",
  "page-header__nav--desktop": "nwbzpjt",
  "main-nav": "ausvvjx",
  "page-header__favorites-count": "kgopbxg",
  "page-header__buttons-item": "fkgeoia",
  "page-header__phone--desktop": "drgu-xx",
  "page-header__nav-wrap": "uxsqpjm",
  "page-header__search--form": "oqagzlo",
  "page-header__buttons-phone": "xdnjn_e",
  "page-header__search--form-visible": "zrmsxfk",
  "page-header__nav-desktop": "smbbxui",
  "page-header__makes-wrap": "askydsa",
  "page-header__logo-block-wrap": "ktld_sx",
  "page-footer__links-list": "aikxjwx",
  "button--compare": "ahlcejp",
  "page-header__buttons-item--search": "aexsmza",
  "page-header__logo": "-_bquuo",
  "page-header__search": "urxqecu",
  "form__field-wrap": "qruxdee",
  "page-header__logo-letters": "_qflldb",
  "page-header__info-wrap": "nysqt-t",
  "page-header__about": "lpdulon",
  "page-header__about-icon": "suzmqts",
  "page-header__phone": "jvtiukw",
  "page-header__contacts": "uzqopcq",
  "page-header__info": "dhghkcx",
  "main-nav--modal": "dnsuscz",
  "main-nav__list": "lvjglfv",
  "site-list__link": "jedynex",
  "site-list": "sqm-yfd",
  "site-list__item": "epxvykd",
  "site-list__link--group": "reisl_z",
  "site-list__item--sub": "jeyykki",
  "site-list__item--group": "kspoyom",
  "site-list__link--group-active": "gmdkzuo",
  "site__list": "q-coung",
  "site-list__link--service": "tiqazck",
  "site-list--desktop": "diwihgd",
  "makes__link": "dbsejdb",
  "makes__icon": "cnfbcto",
  "makes__icon-wrap": "pynzziz",
  "makes__list--models": "z-pueey",
  "makes__link-logo": "eis-ygy",
  "makes__list--modal": "opgci__",
  "makes__icon-wrapper": "datlzxy",
  "makes__title": "cygiffn",
  "makes__letters-list": "wihxugk",
  "makes__letters-link": "vjgjn__",
  "makes__letters-item--inactive": "kpncrp_",
  "makes__count": "dnlvdw-",
  "makes__list": "nkipkas",
  "makes__list--popular": "x_tfsly",
  "makes__list--header": "hlgzige",
  "makes__item": "llcragk",
  "makes__popular-item": "xmiznb_",
  "makes__popular-logo": "rnxefns",
  "makes__popular-link": "qowhrpx",
  "makes__list--makes": "mrbjkle",
  "makes__list--all-catalog": "vxprbsy",
  "button--link": "_lbkzsl",
  "makes__list--all": "jnfqfto",
  "makes__item--model": "_qebqyy",
  "makes__group": "vjpefai",
  "makes__letter-item": "tsdrldl",
  "slider-index": "cace_ty",
  "slider-index__img": "thmc-no",
  "slider-index__item": "jtelpli",
  "slider-index__text": "xwasqqm",
  "slider-index__circle": "tuvpsip",
  "slider-index__text-wrap": "ts_yb_r",
  "slider-index__heading": "mnnpmft",
  "slider-index__content": "ahljnhj",
  "slider-index__media-wrap": "imstso-",
  "slider-index__media": "gepfqvf",
  "slider-index__media--element": "t-_zkfx",
  "slider-index__media--car": "jxb-_v-",
  "stories--modal": "eycpfdf",
  "stories__modal-wrap": "wvviklo",
  "stories__modal": "byux_ci",
  "stories--video": "sisvcpv",
  "stories__offer": "sh-hz-r",
  "stories--benefit": "wpdxpeg",
  "stories__close": "ecerjbn",
  "stories__close-icon": "wybplbx",
  "stories__modal-text": "smauxrq",
  "stories__modal-img-wrap": "mwjaixi",
  "stories__item": "yfb-pil",
  "stories__img": "moeb_g-",
  "stories__text": "gz_iwrm",
  "stories__list": "-mpsnfh",
  "stories__modal_wrapper-wrap": "sbv-lmm",
  "stories--desktop": "sskavln",
  "tabs--modal": "wjdtdwx",
  "tabs__count": "dlkkhsu",
  "tabs__list": "sdcvwbn",
  "tabs__link": "xuzcd_v",
  "tabs__year": "pgdbizn",
  "tabs__item--active": "vj-ocvq",
  "tabs__item--disabled": "ridhmja",
  "tabs__item": "hnpojao",
  "tabs__list--all": "xgnqupi",
  "tabs__list--gallery": "_e_f-go",
  "space-top": "i-qqzcg",
  "heading__year": "ovxtzqz",
  "heading--h3": "mvzvjpr",
  "heading__promo": "gpllals",
  "heading--complectation": "nleehfd",
  "heading-group__img": "xxckgtu",
  "heading-group--catalog": "dvpbsvt",
  "heading-group--logo": "ezemgdf",
  "heading-group__label": "ci-wbmk",
  "heading-group__wrap--complectation": "xsyggxg",
  "heading-group__logo": "uxx_seq",
  "heading-group__wrap--make": "gjoq_gu",
  "heading-group--form": "ttujqjn",
  "heading-group--modal": "nof-oxl",
  "rating": "jozgxrr",
  "heading-group__label--car": "sxetqnw",
  "heading-group__year": "fst_ttv",
  "heading-group--new": "spbe--z",
  "heading-group--h1": "ofljeb_",
  "heading-group--filter": "hejfi-i",
  "heading-group__wrap--mark": "lffhfqr",
  "catalog__credit-label": "uz-zcif",
  "catalog--used": "njifony",
  "catalog--new": "torxgom",
  "catalog__item": "ktmeqez",
  "catalog__buttons": "egugvii",
  "catalog__item--new": "hpesqia",
  "catalog__item--vertical": "ecgwhcz",
  "button--autoteka": "lwqzfql",
  "button__text": "psohhn-",
  "catalog__title-link": "wlodxaw",
  "catalog__title-wrapper": "gvzyyiz",
  "catalog__item--render": "bx_sklj",
  "car__tech-icon": "ijnkegh",
  "catalog__info": "bz-jepg",
  "catalog__img--new": "omkboqx",
  "catalog__mark-logo": "wdxwshp",
  "catalog__title-line": "xcjoptx",
  "catalog__logo": "misjvls",
  "catalog__img--long": "lauhvww",
  "catalog__tech": "mbmcsmj",
  "catalog__price-wrap": "gskmtje",
  "catalog__item--form": "urtwrwa",
  "catalog__item--modal-desktop": "uwctiwp",
  "catalog--modal": "leoyrhp",
  "catalog__filter": "halrvvo",
  "catalog__title": "jeoywhr",
  "catalog__price-wrapper": "qzdtr_e",
  "catalog__title--highlight": "pp_-gip",
  "catalog__title-logo": "f_hyvcz",
  "catalog__no-cars": "-pjenql",
  "catalog__img-link": "mwghhtz",
  "catalog__call-card": "qplmiln",
  "catalog__call-icon": "ewrsenq",
  "catalog__call": "nkjkin-",
  "button--call": "stfbjrb",
  "button--favorite": "-wjk-po",
  "catalog__call-title": "_-bqqvr",
  "catalog__call-dealer": "jhsnuko",
  "catalog__actions": "fkowegk",
  "catalog__year": "caaskma",
  "catalog__run": "vsenech",
  "catalog__title-wrap": "nyikjyx",
  "catalog__price-old": "xcftgxc",
  "catalog__item--desktop-l": "pyvdtak",
  "car__slider": "mnfedxl",
  "catalog__actions-main": "opk-vcl",
  "catalog__actions-main--long": "ihqlilg",
  "button--credit": "euegcy-",
  "catalog__purchase-wrap": "dpqak_o",
  "catalog__price": "cfhoez_",
  "catalog__img-link--single": "pcoy_iv",
  "catalog__tech--no-buttons": "ribigqr",
  "catalog__tech-item": "jfufqge",
  "catalog__buttons--reversed": "_ihjal_",
  "catalog__tech-list": "olhfmmy",
  "catalog__credit-price": "jrbnrpy",
  "catalog__item--small": "yxfzjxc",
  "catalog__dealer-item": "hwisixm",
  "catalog__tech-list--new": "jfevizl",
  "catalog__filter--main": "rdl_uky",
  "catalog__filter--aside": "csvrlwi",
  "catalog__makes": "yzmxzzr",
  "button--show": "-cxouje",
  "catalog__vin": "rqhcffb",
  "catalog__banner": "sridgpw",
  "catalog__banners": "vpfnush",
  "car__tech-item": "sxgekco",
  "catalog__title--small": "hwx_zvf",
  "catalog__filter-cars--expanded": "jfcscfy",
  "catalog__filter-controls": "nouupfv",
  "stock": "dqguyql",
  "catalog__subtitle": "izqjxkj",
  "catalog__purchase": "xqbmhhu",
  "stock--new": "jilcfda",
  "catalog__more-buttons-wrap": "blxn_ec",
  "catalog--single": "hlhtfsk",
  "catalog__item--desktop-form": "ffysowh",
  "catalog__heading": "rpdnv_p",
  "catalog__more-buttons": "pauejcz",
  "button--action": "xqplltk",
  "vin": "izhteng",
  "catalog__img--single": "gffns__",
  "catalog__info-wrap": "r-s---y",
  "catalog__info-wrap--no-buttons": "zlkunlk",
  "button__text-autoteka": "jazbkie",
  "catalog--slider": "ekis-lb",
  "page-main--index": "gmgccav",
  "catalog__filter-cars": "mwdv-zk",
  "filter__more": "mbhpgoy",
  "button--reset": "wyekwge",
  "catalog__main-price-wrap": "fttudva",
  "catalog__filter-header": "mgz_gjb",
  "catalog__filter-item": "buhzawr",
  "catalog__filter-list--expanded": "xtojsti",
  "catalog__desktop-l-wrap": "alrqmzu",
  "button--more": "stxfobo",
  "catalog__img-link--active": "gddl-ol",
  "load": "jchizrg",
  "loaded": "otkxqbu",
  "button--option": "ewepveu",
  "button__back-text": "veuftks",
  "button--services": "yz-rdhe",
  "button__back-arrow": "fedrdvy",
  "button--back": "pvdfuoj",
  "button--video": "tltklhw",
  "button--icon": "ogdgxlq",
  "button--show-active": "jmmmj_w",
  "button__icon": "ftqbr_x",
  "button--download": "rpik__s",
  "button--car": "tfuwzev",
  "button--view-s": "oyybz_j",
  "button--fixed": "utcd-mh",
  "button--form": "ip-kjcl",
  "button--trade-in": "cwzen_t",
  "button--print": "mdacht_",
  "button--filter": "hvgcfix",
  "button--show-price": "vyjirsd",
  "button--show-link": "owetqnx",
  "button--loading": "sk_jnwx",
  "loader": "wqxfurv",
  "button--filter-toggle": "zwx_ywh",
  "button--share": "kj_ybnf",
  "button__autoteka-logo": "nh_syiv",
  "button__text--hide-mobile": "lixnogf",
  "car__buy--new": "ykpzrks",
  "button--text": "qceuvtl",
  "button--disabled": "gzpsxrq",
  "featured__about": "bobbivw",
  "featured__text": "aejmihe",
  "featured__link": "ilcfuhs",
  "featured__item--review": "gcuzovk",
  "featured__item--business": "oe-k_ow",
  "featured__item": "kvqklfp",
  "featured__title": "-l-hyrk",
  "featured__picture": "zfnvjgp",
  "featured__label": "waavvrg",
  "featured__img": "hhin_xq",
  "featured__review-picture": "zzypog-",
  "featured__play-icon": "y-ezdgp",
  "featured__review-img": "mjfg-ak",
  "featured__item--review-active": "lxc_-lh",
  "featured__list": "tljoru-",
  "featured__reviews-map": "eexyqii",
  "featured--promos": "vfjhkhn",
  "featured__banks": "mntgj-h",
  "featured__item--promo": "fbikpnd",
  "featured--reviews": "fqujceo",
  "featured__list--promos": "unfu__n",
  "video__block": "hrm_hkx",
  "video__img-wrap": "wfixciw",
  "video__img": "zlgsqfl",
  "video__text": "lwte-em",
  "video__play": "pikndln",
  "video__play-icon": "rjewsb_",
  "page-footer": "jiqawth",
  "page-footer__links": "rhsrlrs",
  "page-footer__payments": "jotflll",
  "page-footer__payments-item": "iptbrnw",
  "page-footer__payments-logo": "_gulfjc",
  "page-footer__payments-list": "ywfmroi",
  "page-footer__links-item": "qjwglrn",
  "page-footer__bottom": "c_phmje",
  "page-footer__insurance-logo": "vm-gxbl",
  "page-footer__logo": "lp_dwad",
  "page-footer__wrap": "pamaf_d",
  "page-footer__contacts-item": "ryqyk-a",
  "page-footer__logo-img": "tikebp-",
  "page-footer__contacts": "uerk-cx",
  "page-footer__city-wrap": "e-xdldl",
  "page-footer__contacts-list": "lfozngr",
  "page-footer__disclaimer": "rulndnn",
  "page-footer__disclaimer-p": "xwbjens",
  "page-footer__social": "-itevjw",
  "page-footer__social-text": "v-yswg-",
  "page-footer__legal-list": "gigkoht",
  "page-footer__legal-label": "thap_sn",
  "page-footer__city": "a_yvh-n",
  "page-footer__city-icon": "leqbifg",
  "page-footer__social-wrap": "hksqczw",
  "page-footer__legal-item": "dg_dx--",
  "page-footer__makes-logos": "kdpbdwu",
  "page-footer__makes-link": "omnrysr",
  "page-footer__makes-icon": "abboyot",
  "page-footer__makes-icon--cadillac": "dhmq_wn",
  "page-footer__bottom-makes": "ijzuejb",
  "page-footer__cars": "vlrjbet",
  "page-footer__cars-stock-number": "yiuougk",
  "social": "hjcfjhr",
  "social__icon": "cvtpodm",
  "social__link": "oivkwcx",
  "stock--false": "tuvh-nm",
  "stock--true": "-mchlbc",
  "car__top": "presxis",
  "car__info": "opluato",
  "car": "_damajs",
  "car__info-not-found": "wys_lum",
  "car__colors-wrapper": "hop_ygr",
  "car__video-button": "ohmswld",
  "car__video-button-text": "--cfjyd",
  "car__video-icon": "dd-qaoa",
  "car__call-buttons": "fxdktut",
  "car__actions-text--active": "syzejuk",
  "car__actions-text": "uxizsho",
  "car__info-button": "bgwaacb",
  "car__autoteka": "xf_tium",
  "car__autoteka-img": "dah_xdt",
  "car__autoteka-wrap": "wddyrhx",
  "car__top-info": "ic--qfc",
  "car__top-info-wrap": "rcfjhel",
  "car__year": "wpwnik-",
  "car__autoteka-text": "izdhymp",
  "car__buy-block": "ipcggxs",
  "car__actions-block": "zbjrtdo",
  "car__generation": "jwtybgm",
  "car__actions-block--new": "jn_hlcf",
  "car__description": "bdisnp-",
  "text__p": "wsjzxnd",
  "car__actions-buttons": "rpcaadi",
  "car__slider-wrap": "-wdenbr",
  "car__buy": "xxxohgq",
  "car__buy--complecation": "j-xlmtp",
  "car__color": "_nurpef",
  "car__price": "mgk-stn",
  "car__price-conditions": "rdakcna",
  "car__logo": "nr_vhra",
  "car__subtitle": "uixb-pj",
  "tippy": "blcvckx",
  "car__price-wrap": "rriqwrz",
  "car__price-cash": "hramfgu",
  "car__price-block": "ocxrmid",
  "car__price-payment": "skblul-",
  "car__price-additional": "zlsf-vb",
  "car__credit-disclaimer": "selt-pb",
  "car__stock": "gsbfluv",
  "car__buttons": "kklvkav",
  "car__stock--new": "zdhkhbh",
  "car__stock-dealer": "z-fjzkx",
  "car__buy--fixed": "iuyfqtc",
  "car__sticky--new": "mgroxzv",
  "car__tech-item--autoteka": "owmiqaj",
  "car__tech-icon-wrap": "hugyiwi",
  "car__price-bonus": "-rcdwox",
  "checkbox__icon--checked": "xxqkqye",
  "car__info-group": "ikqcwos",
  "car__tech-list": "qs_enoo",
  "car__credit-wrap": "m-uy_dw",
  "car__tech-label": "lgsgncm",
  "car__info-group--new": "zsyygxl",
  "car__color--selected": "ujlif-v",
  "car__color--wrapper": "er-cac_",
  "car__color--highlight": "nzybhlu",
  "car__price-description": "siegafr",
  "switch__tab-element": "zcwcldm",
  "car__info-group--options": "e_isuug",
  "car__sticky": "ipr-od-",
  "car__info-wrap": "-rtnfru",
  "car__info-group--complectation": "ktqvd_x",
  "car__benefits--slider": "fqxqohm",
  "car__top-buttons": "svlxwdq",
  "car__stock-promo": "erfdogg",
  "car__tech-item--desktop": "qtrdhg_",
  "car__benefits": "rqpamov",
  "car__info-tabs": "vnlivhh",
  "car__info-group--characteristics": "q_bcxnl",
  "form__checkbox-text": "u-eh-bn",
  "car__buy-list": "hgsgppm",
  "car__fixed-wrap": "-unpebu",
  "car__info-groups": "iveutva",
  "page-main__car--new": "idsoqcr",
  "car__info-group--terms": "otqawrs",
  "car__slider--new": "luwnvaf",
  "catalog__item-img-wrap": "glvo_fz",
  "car__info-characteristics": "fnbvjpe",
  "car__tech-item--mobile": "drifmrk",
  "car__credit": "-jfic-q",
  "car__colors": "zvpfrwq",
  "benefits__list--car": "dcbrx-x",
  "benefits__item": "hz-jfto",
  "slider-car__item": "xi-jqxm",
  "slider-car__item--thumb": "hfqa-qd",
  "slider__car-img--new": "kqjezgw",
  "slider__car-img": "owlepvf",
  "slider__car--new": "jwnkbdy",
  "slider-car": "dupqtht",
  "slider__car-item--main": "pxpikmw",
  "swier--car-new": "lijhcrv",
  "slider__car-item--thumb": "mtrdhtt",
  "tippy-popper": "meljlky",
  "tippy--question": "uk-fujz",
  "tippy__icon": "-gcawja",
  "tippy__text": "whoxicp",
  "form__field-wrap--car": "tue-nav",
  "form__car-icon": "uuiakzh",
  "form__field-select": "tcrs_me",
  "form__fieldset--inputs": "aarsqgw",
  "form__input-wrapper": "cnztnou",
  "form__checkbox-text-link": "hauyw_t",
  "form__catalog": "rshsbbt",
  "form__field-wrap--car-active": "tx_-_qm",
  "form__range-wrap": "_jcvszm",
  "form__range-wrap--no-top": "ypxgzuj",
  "form__range-name": "rvzet-p",
  "form__block": "ou-eoua",
  "form__block--range": "-rvryqk",
  "form__block--range-loading": "fdownzk",
  "form__range-value": "gl_kniu",
  "form__field-wrap--absolute": "oici_fk",
  "form__icon-search": "zttkdfy",
  "form__field": "ktipskc",
  "form__field--filter-select": "qjsgnvb",
  "form__field-wrap--success": "cogddsi",
  "form__field-wrap--error": "wratkrn",
  "form__field-wrap--select": "gj_rdgj",
  "form__field-wrap--range": "bhyovu_",
  "form__total": "vxktdbl",
  "form__total-payment": "-rzsrmx",
  "form__agree": "jxqwshn",
  "form__agree-text": "pxunejz",
  "form__field-arrow": "gkafmwo",
  "form__field--search": "kjznpv_",
  "form__icon-search--filter": "mjtfeam",
  "form__complectation": "bmrzu_h",
  "form__field-wrap--search": "xunsyd-",
  "filter": "jhkb-zs",
  "catalog__filters-view-buttons": "zdpxhng",
  "filter__button--active": "jawqulc",
  "filter__catalog-wrap": "xxihixq",
  "filter__buttons-wrap": "mdkbsrs",
  "filter--expanded": "phxgce_",
  "filter__button": "aq-ikpj",
  "filter__menu-text": "iyo-tiu",
  "catalog__filter-list": "p_fflxq",
  "filter__buttons-filter": "pobasdj",
  "filter__menu-drop": "anqzdse",
  "filter__menu-drop__item": "sxulqbm",
  "catalog__filter-item--loading": "qn-larl",
  "filter__arrow": "rzdre_y",
  "filter__arrow--active": "dsetkvz",
  "filter--modal": "zp_uzuw",
  "filter__menu-list": "abf_kvp",
  "filter__checkbox": "mtqcldc",
  "filter__menu-group": "bntkvq_",
  "filter__menu-list--more": "gckbcys",
  "skeleton": "zesxmbp",
  "filter__buttons-sort-select": "rz-ojfl",
  "filter__buttons": "-ysvclj",
  "catalog__filters-filter-button": "minjpvn",
  "filter__buttons-sort": "rwgmmbb",
  "filter--aside": "mbuiuuq",
  "benefits__icon": "kqc_gpg",
  "benefits__link": "cxlhkqp",
  "benefits--mobile": "auactmi",
  "benefits__text": "ekiich-",
  "benefits__text-strong": "iervcn-",
  "benefits--credit": "ss-upze",
  "benefits--modal": "-ffylzc",
  "modal--credit": "pgjdsse",
  "modal": "qakxrim",
  "main": "bfavsaj",
  "text-left": "jrwq-qh",
  "modal__logo": "epyzhcj",
  "modal__wrap": "-qajses",
  "modal__video": "bazqzme",
  "modal__close-icon": "loinuug",
  "modal__close": "qck_-fo",
  "modal__options": "w_zlntw",
  "modal__buttons": "qmontwc",
  "modal__choose-group": "zzcesdc",
  "modal__rating": "sm_tfzh",
  "modal--search": "hzvgv_g",
  "modal--menu": "ijpbhrz",
  "modal__social": "ylk_sjq",
  "modal--makes": "cpoplbr",
  "features": "iydg_ho",
  "complect": "xofucan",
  "heading-group--modal-only-title": "vxrglga",
  "modal--choose-car": "upkacac",
  "application__form": "b-lweyn",
  "checkbox__text": "prsizdo",
  "checkbox": "-hrqycy",
  "checkbox--filter": "nlnr-mb",
  "checkbox__icon": "tzzzisu",
  "checkbox--error": "noo-ndv",
  "checkbox__icon--disabled": "kmtffrh",
  "checkbox__icon--disabled-checked": "hjizzqz",
  "checkbox__icon--active": "u-agapu",
  "features__item": "vtaijtf",
  "features__rating": "zs_kfb-",
  "features__group": "ntisgfn",
  "features__item--rating": "_mjwvvy",
  "features__item--problem": "cqdyftl",
  "features__indicator": "ziffove",
  "features__item-type": "kwoyjac",
  "features__indicator--problem": "hmxnrjm",
  "features__indicator--clean": "juxdxjp",
  "features--dealer": "oiuswqp",
  "features__item-content": "ihtiqzw",
  "features__list--column": "odjrryg",
  "features--complect": "fcqivxx",
  "map": "fwpxmxx",
  "map--dealer": "funkcrs",
  "ymap-container": "krfzhsf",
  "application__img": "qdu_sxr",
  "application__form__car-wrapper": "cekfbiz",
  "application__expander": "rwdswry",
  "application__expander-icon": "olscnf_",
  "application__expander-icon--expanded": "ueoacfl",
  "form-banks-wrapper": "ijcqizh",
  "application__expander-header": "taeegoa",
  "application__header-bank": "abmojpc",
  "application__catalog": "ghxsqyh",
  "application__banks-form": "uqgowkh",
  "application__banks-img": "-uptywb",
  "application__banks-img--alfa": "hkshsro",
  "application__banks-text": "-aotxeg",
  "application__banks": "ztrxgnt",
  "application__banks--single": "-za-ojy",
  "application__form-img-wrap": "jsgy-bq",
  "application__banks-item--alfa": "yn_qhjs",
  "application": "zldxagd",
  "application__catalog--modal": "ljaibfn",
  "application__terms": "lzcq_sp",
  "application__choose-car": "kcgsbdi",
  "application--modal": "zwuwifu",
  "application__terms-number": "yrqrmus",
  "application__terms-item": "xxlprku",
  "application__terms-text": "v_xcvke",
  "application__choose-car-icon": "eujddgf",
  "application__choose-car-text": "ayxh_xh",
  "text__wrap": "sbfkfmw",
  "text__content": "-irdmqg",
  "text__img": "dh-jzs-",
  "text__working-hours": "qijyxnz",
  "text__figure": "-ojvylp",
  "text__link": "ppsyogz",
  "text__links": "bidnxfa",
  "text__list": "dcnhpsu",
  "text__o-list": "onirwho",
  "text__o-list-item": "rvcrgln",
  "text__o-list-item--heading": "yxdbjuf",
  "text__list--contacts": "_chybjz",
  "text__list-item": "iquhppt",
  "text__figure-img": "_wishpr",
  "text__contacts-item": "g-pvvfs",
  "text__contacts-label": "_sxkjqk",
  "mt-5": "uwchfkc",
  "mt-4": "xkcsbmb",
  "mt-3": "eyt-oz-",
  "mt-2": "rukyy--",
  "mt-1": "vnupyoz",
  "text__wrap--map": "ob_iysh",
  "text__img-wrap": "vigiyfr",
  "text__contacts-group": "agzpedn",
  "text__img--blog": "sztlplz",
  "text__img-map": "g-dc--c",
  "crumbs": "rhgvrrc",
  "crumbs__link": "ac_bepn",
  "crumbs__item": "jiabeav",
  "pagination": "fqpedfp",
  "pagination__list": "d_cmh-j",
  "pagination__arrow": "vwsboju",
  "pagination__arrow--prev": "auakkwe",
  "pagination__arrow--next": "ijbkibi",
  "pagination__arrow-icon": "ghrqnkg",
  "pagination__item--active": "smnv-vq",
  "pagination__link": "qpcnbut",
  "pagination__item": "jecafek",
  "select": "kjwdznw",
  "select--hidden": "gkqkakx",
  "select__list": "qbmgxxm",
  "select__list--regions": "gbijpaa",
  "select__item": "fa_lgvf",
  "thanks": "kzfvazi",
  "about__picture": "cabbabs",
  "about__panorama": "pknhamu",
  "about__slider": "vcjpzeb",
  "about__img": "lbkkegu",
  "about__text": "uj-wrls",
  "about__block": "ou_muhg",
  "about__pictures": "lsjfbzj",
  "about__map": "vkmweaw",
  "banks__info": "-mdrthx",
  "banks__info-number--stake": "jvwmqul",
  "banks__info-item": "uwwwvtb",
  "banks__main": "kyxvubj",
  "banks__item-link": "lihcudc",
  "banks": "_hkq-mi",
  "banks--modal": "goxqqbw",
  "banks__item-link-hover": "nrjjscr",
  "not-found": "qseojap",
  "not-found__img": "xerksck",
  "loader--button": "shexnvm",
  "range": "tkjwuji",
  "toggle__checkbox": "qlsz-xc",
  "toggle__slot": "nikstiw",
  "toggle__button": "srwfpxa",
  "toggle__logo": "ufupdiw",
  "toggle__logo--sun": "cyegkrg",
  "toggle__sun": "m-jklou",
  "toggle__logo--moon": "qrdsokc",
  "toggle__moon": "oysdydw",
  "toggle": "ibpdk_r",
  "table": "fuaapfh",
  "table__head": "-i-lvmg",
  "table__head-cell": "dy-tdnm",
  "table__sticky-cell": "uabhdcm",
  "table__compare-attribute": "sabdtcf",
  "table__head-data": "iceqnwu",
  "table__group": "y_jhwbp",
  "table--short": "dmplzmy",
  "table__compare-group": "o_hmm_g",
  "table__compare-group--different": "fndqijq",
  "promotions": "utfalbl",
  "promotions__line": "qpbwauq",
  "promotions__item": "ssxmuoi",
  "promotions__item-text": "-gvsnho",
  "promotions__item-image": "dnme_ra",
  "promotions__item-title": "hpbdpr-",
  "slider-about": "fcfjxa-",
  "slider-about__img": "t_mypps",
  "service__wrap": "xcih-l-",
  "service__info": "l-tqci-",
  "service__img-wrap": "-vdhjfw",
  "service__light": "javnqcx",
  "service__logo": "wqyyqqp",
  "service__text": "mnngkyz",
  "service__text-additional": "mracayx",
  "service__img": "_fqqivp",
  "service": "lldfdc-",
  "form-banks": "f__xipi",
  "form-banks__content": "hizgfdq",
  "expander--expanded": "bmxcjli",
  "form-banks__content--selected": "qdzupae",
  "expander__expand-icon": "proqmqk",
  "form-banks__choose": "w_n-ovq",
  "form-banks__container--active": "otivibn",
  "form-banks__offers": "ve_ilml",
  "form-banks__offer": "gcexcud",
  "form-banks__offer-img": "tp-eszd",
  "form-banks__bank-info": "qwqvvfj",
  "form-banks__container": "ifnm_yn",
  "form-banks__rate-wrapper": "b_jnyth",
  "expander__toggle": "wntm-ci",
  "form-banks__column": "oovanqh",
  "form-banks__normal": "vtvy-fs",
  "form-banks__bold": "jhktscv",
  "application__expander--mobile": "nivyppu",
  "switch": "lwrxsgb",
  "switch__tab": "nuszskj",
  "switch__tab--active": "v-_ikxv",
  "warranty__block-item": "_lvzjhq",
  "bold": "jgzpnpx",
  "warranty__img": "-jzkmrv",
  "warranty__img-bottom": "mogetzm",
  "warranty__tabs": "_uitlwa",
  "warranty__steps-img": "rabqkoh",
  "warranty__block--image": "lonebeu",
  "warranty__first-company": "cuv_fzy",
  "warranty__programs-title": "inxrxcf",
  "warranty__programs-buttons": "dzojrtr",
  "button-blue--transparrent": "olcxkmk",
  "warranty__programs-selected": "oxvjzsv",
  "warranty__programs-selected-block": "ea-fn-b",
  "warranty__steps-title": "oeoy_bc",
  "warranty__steps": "lapahku",
  "warranty__steps-item": "srgyzud",
  "warranty__steps-item_text": "effxflc",
  "warranty__programs-media": "jyzopta",
  "withShadow": "p-xawtn",
  "withShadow--right": "ittrrxl",
  "withShadow--left": "lye-kca",
  "firstImage": "lkhis-i",
  "secondImage": "junnegp",
  "warranty__programs-selected-wrap": "ufyxwpi",
  "warranty__programs-content": "dxak-fn",
  "warranty__broke": "hyesoru",
  "menu__link": "wocuom-",
  "menu__item": "g_xafjs",
  "menu": "gftdkhu",
  "menu__arrow": "xtkayck",
  "menu__arrow--active": "jgzbxei",
  "captcha": "vqgavdj",
  "default__wrapper": "rk-k_ng",
  "makes__bottom": "aibbzaq",
  "slider-index__button": "qsfmafy",
  "featured__link-title": "moekilh",
  "sets": "v-_tfcg",
  "footer": "iysrrkw",
  "footer__inner": "c_cecrm",
  "footer__nav-item": "p_jfahq",
  "footer__contacts-item": "fwprgtb",
  "footer__content": "fin_kls",
  "footer__legal": "nuv-bzc",
  "footer__legal-item": "znzqmoe",
  "footer__logo": "veqxfoq",
  "footer__contacts": "idvxntj",
  "heading--slider": "nip_xgi",
  "modal__contacts": "rddrklm",
  "modal__contacts-wrap": "ennfadb",
  "modal__contacts-item": "hkjrucg",
  "header": "bjygjxx",
  "header__inner": "_-offkt",
  "header__content": "vozktec",
  "header__info": "ehqecqf",
  "header__info-contacts": "scmvmme",
  "header__info-item": "y-nssqi",
  "header__buttons": "rmyemzg",
  "header__buttons-item": "wlogziq",
  "header__buttons-item--phone": "knwry-r",
  "header__nav": "pjprhpx",
  "header__logo-desktop": "kiap-ql",
  "header__buttons-item--map": "olffpoy",
  "header__logo": "jbsuvto"
}
export type ObfuscatedJSON = typeof obfuscatedJSON
