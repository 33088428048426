import { useSeoTags } from '#imports';
import type { Ref } from 'vue';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import type { SeoTemplateCrumbs } from '~/types/graphql';

type TSeoTemplateProps = {
  $crumbs: Ref<SeoTemplateCrumbs[]>,
  _route: Partial<RouteLocationNormalizedLoaded>
    & Required<Pick<RouteLocationNormalizedLoaded, 'name' | 'query' | 'params' | 'path' | 'fullPath'>>,
  $title: Ref<string>
};

export const useSeoTemplate = ({ _route: route, $title, $crumbs }: TSeoTemplateProps,
  force = false, additionalContext = {}) => {
  const seoTagsStore = useSeoTags();
  seoTagsStore.applySeoToHead(route as RouteLocationNormalizedLoaded, ({ h1, crumbs }) => {
    $title.value = h1;
    $crumbs.value = crumbs;
  }, force, additionalContext);
};
