export default defineNuxtPlugin({
  setup() {
    onNuxtReady(async () => {
      const fancyBox = await import('@fancyapps/ui');
      const lazySizes = await import('lazysizes');
      lazySizes.init();
      fancyBox.Fancybox.bind('[data-fancybox]', {
        Hash: false,
        contentClick: 'iterateZoom',
        Images: {
          protected: true,
        },
      });
    });
  },
});
