export const appPageTransition = false

export const appLayoutTransition = false

export const appHead = {"meta":[{"charset":"UTF-8"},{"name":"yandex-verification","content":"f70bc9bad3cb4352"},{"name":"theme-color","content":"#D31A2C"},{"name":"http-equiv=\"X-UA-Compatible","content":"ie=edge"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"},{"name":"apple-mobile-web-app-capable","content":"yes"},{"name":"mobile-web-app-capable","content":"yes"},{"name":"apple-mobile-web-app-status-bar-style","content":"black-translucent"}],"link":[{"rel":"preconnect","href":"https://api.avalon-cars.ru"},{"rel":"icon","href":"/favicon.ico","sizes":"any"},{"rel":"icon","href":"/favicon.svg","type":"image/svg+xml"},{"rel":"mask-icon","href":"/mask-icon.svg","color":"#D31A2C"},{"rel":"manifest","href":"/manifest.json"},{"rel":"apple-touch-icon","href":"https://api.avalon-cars.ru/apple-touch-icon.png","type":"image/png","sizes":"180x180"},{"rel":"apple-touch-icon","href":"/apple-touch-icon-512x512.png","type":"image/png","sizes":"512x512"},{"rel":"apple-touch-icon","href":"/apple-touch-icon-120x120-precomposed.png","type":"image/png","sizes":"120x120"},{"rel":"apple-touch-icon","href":"/apple-touch-icon-120x120.png","type":"image/png","sizes":"120x120"}],"style":[],"script":[{"innerHTML":"(function (m, e, t, r, i, k, a) {\n    m[i] = m[i] || function () {\n      (m[i].a = m[i].a || []).push(arguments);\n    };\n    m[i].l = 1 * new Date();\n    k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a);\n  }(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym'));","async":true,"defer":true,"id":"yandex-metrika"},{"src":"https://smartcaptcha.yandexcloud.net/captcha.js","defer":true}],"noscript":[],"htmlAttrs":{"lang":"ru"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = false

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"activeClass":"active","exactActiveClass":"active","componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false