
import { defineNuxtPlugin, useRuntimeConfig } from '#imports';
import PrimeVue from 'primevue/config';

false
import primePassThrough from '@/assets/passthrough/index.mjs';

import primeTheme from '@/assets/primevue-theme/index.mjs';


export default defineNuxtPlugin(({ vueApp }) => {
  const runtimeConfig = useRuntimeConfig();
  const config = runtimeConfig?.public?.primevue ?? {};
  const { usePrimeVue = true, options = {} } = config;
  const pt = { pt: primePassThrough };
  const theme = { theme: primeTheme || options?.theme };

  usePrimeVue && vueApp.use(PrimeVue, { ...options, ...pt, ...theme });
  
  false
});
        