// noinspection t

import type { SettingSite } from '~/store/settingsStore';
import type { SeoTemplateCrumbs as Crumb, Offer } from '~/types/graphql';
import type { TOfferTransformed } from '~/composables/useCar';

type TJsonLdArgs = {
  settings: SettingSite,
  domain: string,
  crumbs: Crumb[],
  fullPath: string,
}

export const useJsonLd = ({ settings, domain, crumbs, fullPath }: TJsonLdArgs) => computed(() => ({
  common: [
    {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: settings.dealer_title,
      url: `https://${domain}`,
      logo: `https://${domain}/preview.png`,
      address: settings.contact_address,
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: settings.phone,
        contactType: 'customer service',
        areaServed: 'RU',
        availableLanguage: 'Russian',
      },
    },
    {
      '@context': 'https://schema.org/',
      '@type': 'Order',
      seller: {
        '@type': 'Organization',
        name: settings.dealer_title,
        image: `https://${domain}/preview.png`,
        telephone: settings.phone,
      },
    },
  ],
  crumbs: {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: crumbs.map((crumb, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': `https://${domain}${crumb.link}`,
        name: crumb.title,
      },
    })),
  },
  'category-mark-folder-offer': (offer: Offer | TOfferTransformed, description: string) => {
    if (!offer) return [];
    const isNew = useCar(offer).isNew(offer);
    return [
      {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: offer.name,
        image: offer.images[0].medium,
        description,
        brand: offer.mark.title,
        gtin14: 2918,
        sku: 2918,
        offers: {
          '@type': 'Offer',
          url: `https://${domain}${fullPath}`,
          priceCurrency: 'RUB',
          price: offer.price,
          priceValidUntil: '2029-12-31',
          availability: `${offer.is_active ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock'}`,
          itemCondition: 'https://schema.org/UsedCondition',
        },
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: 5,
          ratingCount: ((Math.random() * (20 - 10)) + 10).toFixed(),
        },
      },
      {
        '@context': 'https://schema.org/',
        '@type': 'QuantitativeValue',
        unitCode: 'KMT',
        value: useCar(offer).isNew(offer) ? 'Новый' : offer.run,
      },
      {
        '@context': 'https://schema.org/',
        '@type': 'OfferItemCondition',
        name: useCar(offer).isNew() ? 'new' : 'used',
      },
      {
        '@context': 'https://schema.org/',
        '@type': 'EngineSpecification',
        name: isNew
          // eslint-disable-next-line max-len,vue/max-len
          ? `${offer.modification.engine_volume} л. ${offer.modification.engine_power} л.с. ${offer.modification.gearbox.title}`
          : `${offer.engine_volume} л. ${offer.engine_power} л.с.`,
      },
    ];
  },
}),
);
