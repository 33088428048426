import type { RouterConfig } from '@nuxt/schema';
import type { RouteRecordRaw } from 'vue-router';
import type { TRouteNames } from '~/app/routes';
import { routes as appRoutes } from '~/app/routes';

const routeResolve = () => {
  const routesWithComponent: Record<TRouteNames, Pick<RouteRecordRaw, 'component'>> = {
    home: {
      component: () => import('~/pages/home.vue'),
    },
    about: {
      component: () => import('~/pages/about.vue'),
    },
    banks: {
      component: () => import('~/pages/banks.vue'),
    },
    buyout: {
      component: () => import('~/pages/buyout.vue'),
    },
    contacts: {
      component: () => import('~/pages/contacts.vue'),
    },
    exchange: {
      component: () => import('~/pages/exchange.vue'),
    },
    favorites: {
      component: () => import('~/pages/favorites.vue'),
    },
    stocks: {
      component: () => import('~/pages/stocks.vue'),
    },
    installments: {
      component: () => import('~/pages/installments.vue'),
    },
    privacy: {
      component: () => import('~/pages/privacy.vue'),
    },
    reviews: {
      component: () => import('~/pages/reviews.vue'),
    },
    thanks: {
      component: () => import('~/pages/thanks.vue'),
    },
    credit: {
      component: () => import('~/pages/credit.vue'),
    },
    'credit-bank': {
      component: () => import('~/pages/credit.vue'),
    },
    category: {
      component: () => import('~/pages/catalog.vue'),
    },
    'category-mark': {
      component: () => import('~/pages/catalog.vue'),
    },
    'category-mark-folder': {
      component: () => import('~/pages/catalog.vue'),
    },
    'category-mark-folder-offer': {
      component: () => import('~/pages/catalog.vue'),
    },
    'category-set-setname': {
      component: () => import('~/pages/catalog.vue'),
    },
    warranty: {
      component: () => import('~/pages/warranty.vue'),
    },
  };

  return routesWithComponent;
};

export default <RouterConfig> {
  routes: (_routes) => [
    ...Object.entries(appRoutes).map(([key, value]) => {
      return {
        ...value,
        component: routeResolve()[key as TRouteNames].component,
      };
    }),
  ],
};
