import type { DocumentNode } from 'graphql/language';
import { useSiteDomain } from '#imports';

type Clients = 'default' | 'new';

export const request = async <Response, Request = undefined>(
  query: DocumentNode,
  variables: Omit<Request, 'site_id'>,
  client: Clients = 'default') => {
  const { siteId, siteIdNew } = useSiteDomain();
  const assignVariables = {
    site_id: client === 'new' ? siteIdNew : siteId,
  };
  const params = { ...assignVariables, ...variables };
  const removeEmptyParams = Object.fromEntries(Object.entries(params).filter(([, v]) => v != null));
  return useAsyncQuery<Response>({ query, variables: removeEmptyParams, clientId: client });
};
// todo figure out need this or nah

export const noAsyncRequest = <Response, Request = undefined>(
  query: DocumentNode,
  variables: Omit<Request, 'site_id'>,
  client: Clients = 'default') => {
  const { siteId, siteIdNew } = useSiteDomain();
  const assignVariables = {
    site_id: client === 'new' ? siteIdNew : siteId,
  };
  const params = { ...assignVariables, ...variables };
  const removeEmptyParams = Object.fromEntries(Object.entries(params).filter(([, v]) => v != null));
  return useQuery<Response>(query, removeEmptyParams, {
    clientId: client,
  });
  // return useQuery<Response>(query, removeEmptyParams, { fetchPolicy: 'no-cache' });
};
export const postRequest = async <Response, Request = undefined>(
  query: DocumentNode,
  variables: Omit<Request, 'site_id'>,
  client: Clients = 'default') => {
  const { siteId, siteIdNew } = useSiteDomain();
  const assignVariables = {
    site_id: client === 'new' ? siteIdNew : siteId,
  };
  const params = { ...assignVariables, ...variables };
  const removeEmptyParams = Object.fromEntries(Object.entries(params).filter(([, v]) => v != null));
  return useMutation<Response>(query, { variables: removeEmptyParams, clientId: client });
};
