import { defineNuxtPlugin, useSeoTemplate, useNuxtApp } from '#imports';

export default defineNuxtPlugin({
  name: 'seo',
  enforce: 'post',
  parallel: true,
  hooks: {
    'page:loading:end': () => { // !! FOR CLIENT-SIDE
      useSeoTemplate(useNuxtApp());
    },
    'vue:setup': () => { // !! FOR SSR-SIDE
      useSeoTemplate(useNuxtApp());
    },
  },
});
