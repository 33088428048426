import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';

const semantic = {
  mask: {
    background: 'rgba(23,23,25,.95)',
  },
  overlay: {
    modal: {
      background: 'var(--color-section)',
      borderColor: 'none',
      padding: '0',
    },
    select: {
      background: 'var(--color-section)',
      border: {
        color: 'var(--color-divider)',
        radius: '0',
      },
    },
  },
  content: {
    background: 'var(--color-bg)',
  },
  primary: {
    color: 'var(--color-primary)',
  },
  list: {
    option: {
      border: {
        radius: '0',
      },
      selected: {
        background: 'var(--color-primary)',
        color: 'white',
        focus: {
          background: 'var(--color-primary)',
          color: 'white',
        },
      },
      color: 'var(--color-text)',
      focus: {
        background: 'var(--color-primary)',
        color: 'white',
      },
    },
  },
  form: {
    field: {
      // color: 'var(--color-text)',
      shadow: 'none',
      background: 'var(--color-section)',
      padding: {
        x: '16px',
        y: '11px',
      },
      focus: {
        border: {
          color: 'var(--color-text)',
        },
      },
      border: {
        color: 'var(--color-text-secondary)',
        radius: '4px',
      },
    },
  },
  highlight: {
    color: 'var(--color-primary)',
  },
};

const index = {
  semantic: {
    colorScheme: {
      dark: semantic,
      light: semantic,
    },
  },
  components: {
    skeleton: {
      root: {
        borderRadius: '{content.border.radius}',
      },
      colorScheme: {
        light: {
          root: {
            background: 'var(--color-bg)',
            animationBackground: 'var(--color-section)',
          },
        },
        dark: {
          root: {
            background: 'var(--color-bg)',
            animationBackground: 'var(--color-section)',
          },
        },
      },
    },
    select: {
      dropdown: {
        width: 'auto',
      },
      root: {
        background: 'var(--color-section)',
        placeholderColor: 'var(--color-text)',
        paddingX: 0,
        paddingY: 0,
        focusBorderColor: 'var(--color-text)',
      },
      overlay: {
        background: 'var(--color-bg)',
      },
    },
    dataview: {
      header: {
        padding: '0',
        borderWidth: '0',
      },
      paginatorBottom: {
        borderWidth: '0',
      },
    },
    paginator: {
      padding: '24px 0 0 0',
      navButton: {
        selectedBackground: 'none',
      },
    },
  },
  directives: {
    tooltip: {
      root: {
        padding: '0',
        gutter: '0',
      },
    },
  },
};

export default { preset: definePreset(Aura, index), options: { darkModeSelector: '' } };
