import routerOptions0 from "/home/d/didisher2/avalon-cars.ru/www/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/d/didisher2/avalon-cars.ru/www/app/router.options.ts";
const configRouterOptions = {
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}