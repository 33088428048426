import { defineStore } from 'pinia';
import { request } from '~/utils/request';
import type { Offer, OfferQueryVariables, OffersQueryVariables, OfferTypePagination } from '~/types/graphql';
import { offersGql } from '~/apollo/queries/offer/offers';
import { offerGql } from '~/apollo/queries/offer/offer';
import { useCookie } from '#imports';
import { useNormalizedFilters } from '~/composables/useNormalizedFilters';
import type { ComputedRef } from 'vue';
import { newOffersGql } from '~/apollo-new/queries/newOffers';
import type {
  FolderQueryVariables, FoldersNewQueryVariables, FolderType,
  FolderTypePagination,
  NewOffersQueryVariables,
} from '~/types/new/graphql';
import { foldersGql } from '~/apollo-new/queries/folders';
import { newOfferPageGql } from '~/apollo-new/queries/newOffer';
import { folderGql } from '~/apollo-new/queries/folder';

type OffersState = {
  offers: OfferTypePagination | null;
  offer: Offer | null;
  visited: Set<Offer['id']>;
  _visitedCookieKey: string,
};

export type OffersVars<T = OffersQueryVariables> = {
  [k in keyof T]: unknown | ComputedRef<unknown | undefined>;
}

export const useOffersStore = defineStore('offers', {
  state: (): OffersState => {
    const { cookieKeys: { visitedCookieKey } } = useAppConfig();
    const visited = useCookie<string | null>(visitedCookieKey);
    return {
      offers: null,
      offer: null,
      visited: visited.value ? new Set(visited.value.toString().split(',').map((i) => +i)) : new Set(),
      _visitedCookieKey: visitedCookieKey,
    };
  },

  actions: {
    async fetchNewFolders<T extends FoldersNewQueryVariables>(variables: Partial<T>)
      : Promise<(FolderType & { __is_folder__: true, external_id: number })[]> {
      const data = await request<{
        folders: FolderTypePagination
      }, Partial<T>>(foldersGql, {
        ...variables,
      }, 'new');
      return data.data.value.folders.data.map((i: FolderType) => ({
        ...i,
        __is_folder__: true,
        external_id: i.id,
      }));
    },
    async fetchOffersNew<T extends OffersVars<NewOffersQueryVariables>>(variables: Partial<T>){
      const variablesTransformed = useNormalizedFilters(variables, 'new');

      const { categoryNew } = useCategory();

      const { data } = await request<{
        offers: OfferTypePagination
      }, Partial<T>>(newOffersGql, variablesTransformed as T, 'new');

      const dataMapped = data.value.offers.data.map((i: Offer) => {
        i.external_id = i.id;
        i.category_enum = categoryNew;
        return i;
      });

      return { ...data.value.offers, data: dataMapped };
    },
    async fetchOffers<T extends OffersVars | OffersVars<NewOffersQueryVariables>>(variables: Partial<T>) {
      const { transform } = useCategory('category' in variables ? variables.category as string : 'used');
      const { isNew } = useCategory(transform() as string);

      if(isNew()){
        return this.fetchOffersNew(variables);
      }

      const variablesTransformed = useNormalizedFilters(variables, 'used');

      const { data: { value: { offers } } } = await request<{
        offers: OfferTypePagination
      }, Partial<T>>(offersGql, variablesTransformed as T, 'default');

      this.offers = offers;
      return offers;
    },
    async fetchOfferNew<T extends OfferQueryVariables>(variables: Partial<T>){
      const { categoryNew } = useCategory();

      const { data: { value: { offer } } } = await request<{
        offer: Offer;
      }, Partial<T>>(newOfferPageGql, { ...variables, id: variables.external_id }, 'new');

      offer.external_id = offer.id;
      offer.category_enum = categoryNew;
      this.offer = offer;
      return useCar(offer).getTransformedOffer();
    },
    async fetchOffer<T extends OfferQueryVariables>(variables: Partial<T> & { category?: 'cars' | 'new' }) {
      const { transform } = useCategory('category' in variables ? variables.category as string : 'cars');
      const { isNew } = useCategory(transform() as string);

      if(isNew()){
        return this.fetchOfferNew(variables);
      }

      const { data: { value: { offer } } } = await request<{
        offer: Offer;
      }, Partial<T>>(offerGql, variables, 'default');
      this.offer = offer;
      return offer;
    },
    fetchSimilar(offer: Offer, limit = 6): Promise<{ offers: OfferTypePagination }> {
      const { onResult, result, loading } = noAsyncRequest<
        { offers: OfferTypePagination }, Partial<OffersQueryVariables>>(offersGql, {
          page: 1,
          limit,
          except_external_id: offer.external_id,
          mark_slug_array: [offer.mark.slug],
          folder_slug_array: [offer.folder.slug],
          generation_slug_array: [offer.generation.slug],
          engine_type_id_array: [Number(offer.engineType.id)],
          gearbox_id_array: [Number(offer.gearbox.id)],
        });
      return loading.value ? new Promise((resolve) => {
        onResult(({ data }) => {
          resolve(data);
        });
      }) : Promise.resolve(result.value!);
    },
    async fetchNewFolder<T extends FolderQueryVariables>(variables: Partial<T>) {
      const { data: { value: { folder } } } = await request<{
        folder: FolderType
      }, Partial<T>>(folderGql, variables, 'new');
      folder.category_enum = 'new';
      folder['__is_folder__'] = true;
      folder['is_active'] = true;
      return folder!;
    },
  },
});
