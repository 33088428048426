const pt = {
  drawer: {
    root: {
      style: {
        height: '100%',
      },
    },
    closeButton: {
      style: {
        position: 'absolute',
        right: '0',
        top: '60px',
      },
    },
    content: {
      style: {
        padding: 0,
      },
    },
    header: {
      style: {
        display: 'block',
        padding: 0,
      },
    },
  },
  dialog: {
    root: {
      style: {
        'max-height': '100%',
      },
    },
    headerActions: {
      style: {
        display: 'none',
      },
    },
  },
  accordionHeader: {
    root: {
      style: {
        width: '100%',
      },
    },
  },
  inputnumber: {
    root: {
      style: {
        width: '100%',
      },
    },
  },
  dropdown: {
    option: {
      style: {
        borderBottom: '1px solid var(--color-divider)',
      },
    },
    overlay: {
      style: {
        marginTop: 0,
        'margin-top': 0,
        margin: 0,
        zIndex: 5,
      },
    },
  },
  select: {
    overlay: {
      style: {
        zIndex: '1',
        'z-index': 1,
      },
    },
    option: {
      style: {
        borderBottom: '1px solid var(--color-divider)',
      },
    },
    dropdownicon: {
      style: {
        'margin-left': 'auto',
      },
    },
  },
  tab: {
    root: {
      class: 'switch__tab',
    },
  },
  tablist: {
    activeBar: {
      style: {
        display: 'none',
      },
    },
    content: {
      class: 'tabs',
    },
    tablist: {
      class: 'switch',
    },
  },
};

export default pt;
