export default defineNuxtPlugin({
  name: 'counters',
  parallel: true,
  enforce: 'post',
  hooks: {
    'page:finish': () => { // for client-routing
      const { $settings, _route, $isLighthouse } = useNuxtApp();
      if ($isLighthouse.value || (import.meta.env.DEV && !useRuntimeConfig().public.IS_METRIKA_ENABLED)) {
        useHead({
          script: [
            {
              id: 'yandex-metrika',
              innerHTML: 'null',
            },
          ],
        });
        return;
      }
      window.ym($settings.ym, 'hit', `${_route.fullPath}`);
    },
    'vue:setup': () => { // for server & client
      const { $settings, $isLighthouse } = useNuxtApp();
      if ($isLighthouse.value || (import.meta.env.DEV && !useRuntimeConfig().public.IS_METRIKA_ENABLED)) {
        return;
      }
      callOnce(() => {
        if(!$settings.gtag) return;
        useHead({
          script: [
            {
              async: true,
              src: `https://www.googletagmanager.com/gtag/js?id=${$settings.gtag}`,
            },
            {
              async: true,
              innerHTML: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${$settings.gtag}');`,
            },
          ],
        });
      });
    },
    'app:mounted': (app) => { // for client
      if (app.$nuxt.$isLighthouse.value || (import.meta.env.DEV && !useRuntimeConfig().public.IS_METRIKA_ENABLED)) {
        useHead({
          script: [
            {
              id: 'yandex-metrika',
              innerHTML: 'null',
            },
          ],
        });
        return;
      }
      window.dataLayer = window.dataLayer || [];
       
      window._tmr = window._tmr || [];
      window.ym(app.$nuxt.$settings.ym, 'init', {
        defer: true,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        ecommerce: 'dataLayer',
      });

       
      window._tmr.push({
        id: app.$nuxt.$settings.ym,
        type: 'pageView',
        start: (new Date()).getTime(),
        pid: 'USER_ID',
      });
       
      (function (d, w, id) {
        if (d.getElementById(id)) return;
        const ts = d.createElement('script');
        ts.type = 'text/javascript';
        ts.async = true;
        ts.id = id;
        ts.src = 'https://top-fwz1.mail.ru/js/code.js';
         
        const f = function () {
          // eslint-disable-next-line
          const s = d.getElementsByTagName('script')[0];
           
          // @ts-expect-error ts-migrate(2554)
          s.parentNode.insertBefore(ts, s);
        };
        // @ts-expect-error ts-migrate(2554
         
        if (w.opera == '[object Opera]') {
          d.addEventListener('DOMContentLoaded', f, false);
        } else {
          f();
        }
      }(document, window, 'tmr-code'));
    },
  },
});
