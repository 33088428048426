// noinspection t

import Mustache from 'mustache';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import type { DeepNonNullable } from 'ts-essentials';
import { seo } from '#nuxt-prepare';
import { useOffersStore } from '~/store/offersStore';
import { engineVolume, numberFormat } from '~/utils/filters';
import { type BankSlug, useSets, useSettingsStore, useSiteDomain } from '#imports';
import type { SeoTemplateQuery, SeoTemplateCrumbs as Crumb, MarkFolder, SeoTemplate, Offer } from '~/types/graphql';
import { useBanksStore } from '~/store/banksStore';
import { useJsonLd } from '~/composables/useJsonLd';

type SeoStoreState = {
  seoTags: DeepNonNullable<SeoTemplateQuery>;
  prevUpdateLocation: string | null;
}

export const useSeoTags = defineStore('seoTags', {
  state: (): SeoStoreState => {
    if (!seo || !seo.seoTemplate) {
      throw createError({
        statusCode: 404,
        message: 'Seo тэги не получены в seoTagsStore. Чекни server.prepare.ts при пересборке',
      });
    }
    return {
      seoTags: seo as unknown as DeepNonNullable<SeoTemplateQuery>,
      prevUpdateLocation: null,
    };
  },

  actions: {
    applySeoToHead(
      {
        path,
        fullPath, meta,
        name, params: {
          setname: setParam,
          bank: bankParam, mark: markParam, folder: folderParam, category: categoryParam, offer: offerParam,
        },
      }: Omit<RouteLocationNormalizedLoaded, 'matched' | 'redirectedFrom' | 'hash'>,
      onComplete?: (arg: unknown) => void,
      force: boolean = false,
      additionalContext: { [k:string]: unknown | unknown[], marksList?: MarkFolder[] } = {},
    ) {
      // убедимся, что сео тэги обновились один раз, на сервере или клиенте
      if (this.prevUpdateLocation === path && !force) return;
      this.prevUpdateLocation = path;

      const offerStore = useOffersStore();
      const category = meta?.category as string || categoryParam as string;

      const { offer } = storeToRefs(offerStore);
      const marksStore = useMarksStore();
      if ((name === 'category-mark-folder-offer'
        && (!offer.value || offer.value.external_id !== +offerParam))) return; // will set up later

      const { domain, options, isIndexing } = useSiteDomain();
      const { marksList } = additionalContext;
      const list = marksList || marksStore.allMarks;
      const set = setParam && useSets().find((i) => i.slugName === setParam);
      const mark = markParam && list.find((i) => i.slug === markParam);
      const folder = mark && mark.folders.find((i) => i.slug === folderParam);
      const generation = folder && name === 'category-mark-folder-generation' && 'generations' in folder
        && folder!.generations!.find((i) => i.slug === offerParam);
      const bank = bankParam && useBanksStore().getBySlug(bankParam as BankSlug);

      const mustacheContext = {
        offer: offer.value,
        numberFormat: () => (text: number, render: (s: unknown) => number) => numberFormat(render(text)),
        engineVolume: () => (text: number, render: (s: unknown) => number) => engineVolume(+render(text)),
        setname: set && set.slug,
        set,
        folder,
        mark,
        bank,
        generation,
        year: new Date().getFullYear(),
        city: options.city,
        ...additionalContext,
      };
      // !! просто костыль
      const seoCurrentRoute: SeoTemplate | undefined = JSON.parse(
        JSON.stringify(this.seoTags.seoTemplate),
      ).find((i: { category: string, name: string}) => (
        category ? i.category === category && i.name === name : i.name === name
      ));

      if (!seoCurrentRoute) {
        throw createError({
          statusCode: 404,
          fatal: true,
          message: `Something with ${path} ${name?.toString() || ''} not found`,
        });
      }

      const { description, title, h1, crumbs } = Object.fromEntries(
        Object.entries(seoCurrentRoute).map(([k, v]) => {
          if (!v) return [k, v];
          if (Array.isArray(v)) {
            return [k, v.map((i) => {
              i.link = Mustache.render(i.link, mustacheContext);
              i.title = Mustache.render(i.title, mustacheContext);
              return i;
            })];
          }
          return [k, Mustache.render(v, mustacheContext)];
        }),
      ) as { description: string, title: string, h1: string, crumbs: Crumb[] };

      const { settings  } = useSettingsStore();

      useSeoMeta({
        title,
        ogType: 'website',
        description,
        ogTitle: title,
        ogDescription: description,
        ogUrl: `https://${domain}${path}`,
        ogImage: offer.value
          ? offer.value.images![0].small_webp
          : additionalContext.ogImage as string || `https://${domain}/preview.png`,
        ogLocale: 'ru_RU',
        robots: isIndexing ? 'index, follow': 'noindex, nofollow',
      });

      const ld = useJsonLd({
        settings: settings.value,
        domain,
        crumbs: crumbs || [],
        fullPath,
      });
      const ldCrumbs = crumbs ? ld.value.crumbs : null;
      type ldKey = keyof ReturnType<typeof useJsonLd>['value'];
      type ldCallable = 'category-mark-folder-offer';
      const jsonLdArray = ld.value[name as ldKey]
        ? [...ld.value.common, ldCrumbs, ...ld.value[name as ldCallable](offer.value as Offer, description)]
        : [...ld.value.common, ldCrumbs];

      useHead({
        link: [
          {
            rel: 'canonical',
            href: `https://${domain}${path}`,
          },
        ],
        script: [
          {
            'type': 'application/ld+json',
            id: 'jsonld',
            innerHTML: jsonLdArray.filter(Boolean),
          },
        ],
      });

      if (onComplete) onComplete({ title, description, h1, crumbs });
    },
  },
});
