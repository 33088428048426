import { default as icons_45pagekBLrWAfy9HMeta } from "/home/d/didisher2/avalon-cars.ru/www/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as aboutLvPTjlsSE1Meta } from "/home/d/didisher2/avalon-cars.ru/www/pages/about.vue?macro=true";
import { default as bankstrEvM2q2jzMeta } from "/home/d/didisher2/avalon-cars.ru/www/pages/banks.vue?macro=true";
import { default as buyoutgefFxMMlvcMeta } from "/home/d/didisher2/avalon-cars.ru/www/pages/buyout.vue?macro=true";
import { default as catalogAwyhDvvlYZMeta } from "/home/d/didisher2/avalon-cars.ru/www/pages/catalog.vue?macro=true";
import { default as contactsZ9y7q5DnmnMeta } from "/home/d/didisher2/avalon-cars.ru/www/pages/contacts.vue?macro=true";
import { default as creditEon8eiSR2aMeta } from "/home/d/didisher2/avalon-cars.ru/www/pages/credit.vue?macro=true";
import { default as exchangeSZQq02NixwMeta } from "/home/d/didisher2/avalon-cars.ru/www/pages/exchange.vue?macro=true";
import { default as favoritesdix6Pu4PkHMeta } from "/home/d/didisher2/avalon-cars.ru/www/pages/favorites.vue?macro=true";
import { default as homezTqscEuVDJMeta } from "/home/d/didisher2/avalon-cars.ru/www/pages/home.vue?macro=true";
import { default as installments4QQejxbdClMeta } from "/home/d/didisher2/avalon-cars.ru/www/pages/installments.vue?macro=true";
import { default as privacyfuzoEy78acMeta } from "/home/d/didisher2/avalon-cars.ru/www/pages/privacy.vue?macro=true";
import { default as reviewsKW4NHUCgHIMeta } from "/home/d/didisher2/avalon-cars.ru/www/pages/reviews.vue?macro=true";
import { default as stockseYRgsEu6QeMeta } from "/home/d/didisher2/avalon-cars.ru/www/pages/stocks.vue?macro=true";
import { default as thanksIHzAfch54PMeta } from "/home/d/didisher2/avalon-cars.ru/www/pages/thanks.vue?macro=true";
import { default as warrantyrM4H6uEobaMeta } from "/home/d/didisher2/avalon-cars.ru/www/pages/warranty.vue?macro=true";
export default [
  {
    name: icons_45pagekBLrWAfy9HMeta?.name ?? "icons-page",
    path: icons_45pagekBLrWAfy9HMeta?.path ?? "/_icons",
    meta: { ...(icons_45pagekBLrWAfy9HMeta || {}), ...{"layout":"svg-sprite"} },
    alias: icons_45pagekBLrWAfy9HMeta?.alias || [],
    redirect: icons_45pagekBLrWAfy9HMeta?.redirect,
    component: () => import("/home/d/didisher2/avalon-cars.ru/www/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue").then(m => m.default || m)
  },
  {
    name: aboutLvPTjlsSE1Meta?.name ?? "about",
    path: aboutLvPTjlsSE1Meta?.path ?? "/about",
    meta: aboutLvPTjlsSE1Meta || {},
    alias: aboutLvPTjlsSE1Meta?.alias || [],
    redirect: aboutLvPTjlsSE1Meta?.redirect,
    component: () => import("/home/d/didisher2/avalon-cars.ru/www/pages/about.vue").then(m => m.default || m)
  },
  {
    name: bankstrEvM2q2jzMeta?.name ?? "banks",
    path: bankstrEvM2q2jzMeta?.path ?? "/banks",
    meta: bankstrEvM2q2jzMeta || {},
    alias: bankstrEvM2q2jzMeta?.alias || [],
    redirect: bankstrEvM2q2jzMeta?.redirect,
    component: () => import("/home/d/didisher2/avalon-cars.ru/www/pages/banks.vue").then(m => m.default || m)
  },
  {
    name: buyoutgefFxMMlvcMeta?.name ?? "buyout",
    path: buyoutgefFxMMlvcMeta?.path ?? "/buyout",
    meta: buyoutgefFxMMlvcMeta || {},
    alias: buyoutgefFxMMlvcMeta?.alias || [],
    redirect: buyoutgefFxMMlvcMeta?.redirect,
    component: () => import("/home/d/didisher2/avalon-cars.ru/www/pages/buyout.vue").then(m => m.default || m)
  },
  {
    name: catalogAwyhDvvlYZMeta?.name ?? "catalog",
    path: catalogAwyhDvvlYZMeta?.path ?? "/catalog",
    meta: catalogAwyhDvvlYZMeta || {},
    alias: catalogAwyhDvvlYZMeta?.alias || [],
    redirect: catalogAwyhDvvlYZMeta?.redirect,
    component: () => import("/home/d/didisher2/avalon-cars.ru/www/pages/catalog.vue").then(m => m.default || m)
  },
  {
    name: contactsZ9y7q5DnmnMeta?.name ?? "contacts",
    path: contactsZ9y7q5DnmnMeta?.path ?? "/contacts",
    meta: contactsZ9y7q5DnmnMeta || {},
    alias: contactsZ9y7q5DnmnMeta?.alias || [],
    redirect: contactsZ9y7q5DnmnMeta?.redirect,
    component: () => import("/home/d/didisher2/avalon-cars.ru/www/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: creditEon8eiSR2aMeta?.name ?? "credit",
    path: creditEon8eiSR2aMeta?.path ?? "/credit",
    meta: creditEon8eiSR2aMeta || {},
    alias: creditEon8eiSR2aMeta?.alias || [],
    redirect: creditEon8eiSR2aMeta?.redirect,
    component: () => import("/home/d/didisher2/avalon-cars.ru/www/pages/credit.vue").then(m => m.default || m)
  },
  {
    name: exchangeSZQq02NixwMeta?.name ?? "exchange",
    path: exchangeSZQq02NixwMeta?.path ?? "/exchange",
    meta: exchangeSZQq02NixwMeta || {},
    alias: exchangeSZQq02NixwMeta?.alias || [],
    redirect: exchangeSZQq02NixwMeta?.redirect,
    component: () => import("/home/d/didisher2/avalon-cars.ru/www/pages/exchange.vue").then(m => m.default || m)
  },
  {
    name: favoritesdix6Pu4PkHMeta?.name ?? "favorites",
    path: favoritesdix6Pu4PkHMeta?.path ?? "/favorites",
    meta: favoritesdix6Pu4PkHMeta || {},
    alias: favoritesdix6Pu4PkHMeta?.alias || [],
    redirect: favoritesdix6Pu4PkHMeta?.redirect,
    component: () => import("/home/d/didisher2/avalon-cars.ru/www/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: homezTqscEuVDJMeta?.name ?? "home",
    path: homezTqscEuVDJMeta?.path ?? "/home",
    meta: homezTqscEuVDJMeta || {},
    alias: homezTqscEuVDJMeta?.alias || [],
    redirect: homezTqscEuVDJMeta?.redirect,
    component: () => import("/home/d/didisher2/avalon-cars.ru/www/pages/home.vue").then(m => m.default || m)
  },
  {
    name: installments4QQejxbdClMeta?.name ?? "installments",
    path: installments4QQejxbdClMeta?.path ?? "/installments",
    meta: installments4QQejxbdClMeta || {},
    alias: installments4QQejxbdClMeta?.alias || [],
    redirect: installments4QQejxbdClMeta?.redirect,
    component: () => import("/home/d/didisher2/avalon-cars.ru/www/pages/installments.vue").then(m => m.default || m)
  },
  {
    name: privacyfuzoEy78acMeta?.name ?? "privacy",
    path: privacyfuzoEy78acMeta?.path ?? "/privacy",
    meta: privacyfuzoEy78acMeta || {},
    alias: privacyfuzoEy78acMeta?.alias || [],
    redirect: privacyfuzoEy78acMeta?.redirect,
    component: () => import("/home/d/didisher2/avalon-cars.ru/www/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: reviewsKW4NHUCgHIMeta?.name ?? "reviews",
    path: reviewsKW4NHUCgHIMeta?.path ?? "/reviews",
    meta: reviewsKW4NHUCgHIMeta || {},
    alias: reviewsKW4NHUCgHIMeta?.alias || [],
    redirect: reviewsKW4NHUCgHIMeta?.redirect,
    component: () => import("/home/d/didisher2/avalon-cars.ru/www/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: stockseYRgsEu6QeMeta?.name ?? "stocks",
    path: stockseYRgsEu6QeMeta?.path ?? "/stocks",
    meta: stockseYRgsEu6QeMeta || {},
    alias: stockseYRgsEu6QeMeta?.alias || [],
    redirect: stockseYRgsEu6QeMeta?.redirect,
    component: () => import("/home/d/didisher2/avalon-cars.ru/www/pages/stocks.vue").then(m => m.default || m)
  },
  {
    name: thanksIHzAfch54PMeta?.name ?? "thanks",
    path: thanksIHzAfch54PMeta?.path ?? "/thanks",
    meta: thanksIHzAfch54PMeta || {},
    alias: thanksIHzAfch54PMeta?.alias || [],
    redirect: thanksIHzAfch54PMeta?.redirect,
    component: () => import("/home/d/didisher2/avalon-cars.ru/www/pages/thanks.vue").then(m => m.default || m)
  },
  {
    name: warrantyrM4H6uEobaMeta?.name ?? "warranty",
    path: warrantyrM4H6uEobaMeta?.path ?? "/warranty",
    meta: warrantyrM4H6uEobaMeta || {},
    alias: warrantyrM4H6uEobaMeta?.alias || [],
    redirect: warrantyrM4H6uEobaMeta?.redirect,
    component: () => import("/home/d/didisher2/avalon-cars.ru/www/pages/warranty.vue").then(m => m.default || m)
  }
]