import type { NewOffersQueryVariables } from '~/types/new/graphql';
import type { OffersVars } from '~/store/offersStore';

type TReplaceKey = [key: keyof OffersVars, value: string[] ];
export const useNormalizedFilters = <T extends 'used' | 'new'> (
  variables: Partial<OffersVars>,
  type: T = 'used' as T,
): T extends 'used' ? Partial<OffersVars> : Partial<NewOffersQueryVariables> => {
  const toReplace: {
    used: {
      [k in keyof OffersVars]?: (key: string, value: string[]) => [string, '' | number[] | undefined];
    },
    new: {
      [k in keyof OffersVars]?: (key: string, value: string[] | undefined) =>
        [keyof NewOffersQueryVariables, string | undefined];
    }
  } = {
    used: {
      engine_type_id_array: (key: string, value: string[] | undefined) => [key, value?.[0] && [+value[0]]],
      body_type_id_array: (key: string, value: string[] | undefined) => [key, value?.[0] && [+value[0]]],
      drive_type_id_array: (key: string, value: string[] | undefined) => [key, value?.[0] && [+value[0]]],
      gearbox_id_array: (key: string, value: string[] | undefined) => [key, value?.[0] && [+value[0]]],
    },
    new: {
      engine_type_id_array: (key, value) => ['engine_type', value?.[0]],
      body_type_id_array: (key, value) => ['body_type', value?.[0]],
      drive_type_id_array: (key, value) => ['drive_type', value?.[0]],
      gearbox_id_array: (key, value) => ['gearbox', value?.[0]],
      mark_slug_array: (key,value) => ['mark_slug', value?.[0]],
      folder_slug_array: (key,value) => ['folder_slug', value?.[0]],
    },
  };

  return Object.fromEntries(
    Object.entries(variables)
      .map(([key, value]: TReplaceKey) => (toReplace[type][key] ? toReplace[type][key]!(key, value) : [key, value])),
  );
};
