import { OfferPagination } from '~/apollo/fragments/offer/offerPagination';

export const offersGql = gql`
    ${OfferPagination}
    query offers($site_id: Int, $owner_id: Int, $page: Int, $limit: Int, $category: String, $mark_slug_array: [String], $folder_slug_array: [String], $external_id_array: [Int], $sort: String, $except_external_id: Int, $generation_slug_array: [String], $engine_type_id_array: [Int], $body_type_id_array: [Int], $gearbox_id_array: [Int], $drive_type_id_array: [Int], $owner_id_array: [Int], $year_from: Int, $year_to: Int, $price_from: Int, $price_to: Int, $set: String, $url: String, $type_enum: OfferTypeEnum) {
        offers(
            page: $page
            limit: $limit
            site_id: $site_id
            type_enum: $type_enum
            mark_slug_array: $mark_slug_array
            folder_slug_array: $folder_slug_array
            external_id_array: $external_id_array
            sort: $sort
            except_external_id: $except_external_id
            generation_slug_array: $generation_slug_array
            engine_type_id_array: $engine_type_id_array
            body_type_id_array: $body_type_id_array
            gearbox_id_array: $gearbox_id_array
            drive_type_id_array: $drive_type_id_array
            owner_id_array: $owner_id_array
            year_from: $year_from
            year_to: $year_to
            price_from: $price_from
            price_to: $price_to
            category: $category
            set: $set
            url: $url
            owner_id: $owner_id
        ) {
            ...OfferPagination
        }
    }`;
