import { request } from '~/utils/request';
import type { MarkFolder, MarkFolderGenerationQueryVariables } from '~/types/graphql';
import { markFolderGeneration } from '~/apollo/queries/markFolderGeneration';

type MarksState = {
  allMarks: MarkFolder[];
  popularMarksUsedList: string[];
};
export const useMarksStore = defineStore('marks', {
  state: (): MarksState => ({
    allMarks: [],
    popularMarksUsedList: [
      'audi', 'bmw', 'mercedes-benz', 'ford', 'hyundai', 'kia', 'toyota', 'volkswagen', 'nissan', 'skoda',
    ],
  }),

  getters: {
    popularMarks(state) { // used
      return state.allMarks.filter((mark) => state.popularMarksUsedList.includes(mark.slug));
    },
    // marksQuantity(state) {
    //   return state.allMarks.reduce((acc, mark) => acc + mark.offers_count, 0); // used
    // },
    sortedMarks(state) { // used
      return state.allMarks.sort();
    },
  },

  actions: {
    async fetchAllMarks({ category = 'cars' }: Partial<MarkFolderGenerationQueryVariables> = {}) {
      if (this.allMarks.length && category === 'cars') {
        return this.allMarks;
      }
      const { data: { value: { markFolderGeneration: markFolders } } } = await request<{
        markFolderGeneration: MarkFolder[]
      }, MarkFolderGenerationQueryVariables>(markFolderGeneration, { category: 'cars' });
      if (category === 'cars') {
        this.allMarks = markFolders;
        return this.allMarks;
      }
      return markFolders;
    },
  },
});
