import type { RouteRecordRaw } from 'vue-router';

export type TRouteNames =
  | 'home'
  | 'about'
  | 'banks'
  | 'buyout'
  | 'contacts'
  | 'exchange'
  | 'favorites'
  | 'stocks'
  | 'installments'
  | 'privacy'
  | 'reviews'
  | 'thanks'
  | 'credit'
  | 'credit-bank'
  | 'category'
  | 'category-mark'
  | 'category-mark-folder'
  | 'category-mark-folder-offer'
  | 'category-set-setname'
  | 'warranty';

export const routes: Record<TRouteNames, Pick<RouteRecordRaw, 'name' | 'path' | 'meta'>> = {
  home: {
    name: 'index',
    path: '/',
    meta: {
      existInSitemap: true,
    },
  },
  about: {
    name: 'about',
    path: '/about',
    meta: {
      existInSitemap: true,
    },
  },
  banks: {
    name: 'banks',
    path: '/banks',
    meta: {
      existInSitemap: true,
    },
  },
  buyout: {
    name: 'buyout',
    path: '/buyout',
    meta: {
      existInSitemap: true,
    },
  },
  contacts: {
    name: 'contacts',
    path: '/contacts',
    meta: {
      existInSitemap: true,
    },
  },
  exchange: {
    name: 'exchange',
    path: '/exchange',
    meta: {
      existInSitemap: true,
    },
  },
  favorites: {
    name: 'favorites',
    path: '/favorites',
    meta: {
      existInSitemap: false,
    },
  },
  stocks: {
    name: 'stocks',
    path: '/stocks',
    meta: {
      existInSitemap: true,
    },
  },
  installments: {
    name: 'installments',
    path: '/installments',
    meta: {
      existInSitemap: true,
    },
  },
  privacy: {
    name: 'privacy',
    path: '/privacy',
    meta: {
      existInSitemap: true,
    },
  },
  reviews: {
    name: 'reviews',
    path: '/reviews',
    meta: {
      existInSitemap: true,
    },
  },
  thanks: {
    name: 'thanks',
    path: '/thanks',
    meta: {
      existInSitemap: false,
    },
  },
  credit: {
    name: 'credit',
    path: '/credit',
    meta: {
      existInSitemap: true,
    },
  },
  'credit-bank': {
    name: 'credit-bank',
    path: '/credit/:bank()',
    meta: {
      existInSitemap: false,
    },
  },
  category: {
    name: 'category',
    path: '/:category()',
    meta: {
      middleware: 'offers-pages-validate',
      existInSitemap: false,
    },
  },
  'category-mark': {
    name: 'category-mark',
    path: '/:category()/:mark()',
    meta: {
      middleware: 'offers-pages-validate',
      existInSitemap: false,
    },
  },
  'category-mark-folder': {
    name: 'category-mark-folder',
    path: '/:category()/:mark()/:folder()',
    meta: {
      middleware: 'offers-pages-validate',
      existInSitemap: false,
    },
  },
  'category-mark-folder-offer': {
    name: 'category-mark-folder-offer',
    path: '/:category()/:mark()/:folder()/:offer()',
    meta: {
      middleware: 'offers-pages-validate',
      existInSitemap: false,
    },
  },
  'category-set-setname': {
    name: 'category-set-setname',
    path: '/:category()/set-:setname()',
    meta: {
      middleware: 'offers-pages-validate',
      existInSitemap: false,
    },
  },
  warranty: {
    name: 'warranty',
    path: '/warranty',
    meta: {
      existInSitemap: true,
    },
  },
} as const;
