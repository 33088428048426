import { GenerationType } from '~/apollo/fragments/generation/generationType';

export const markFolderGeneration = gql`
    ${GenerationType}
    query markFolderGeneration($site_id: Int! $category: String) {
    markFolderGeneration(site_id: $site_id, category: $category) {
        id,
        title,
        title_rus,
        slug,
        offers_count,
        logo {
            emblem_svg_xml
        }
        folders {
            id,
            site_text,
            title,
            title_rus,
            slug,
            offers_count,
            generations {
                ... GenerationType
            }
        }
    }
}`;
