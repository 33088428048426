export default defineNuxtPlugin({
  name: 'favs',
  parallel: true,
  enforce: 'pre',
  async setup() {
    const { cookieKeys: { favoritesCookieKey } } = useAppConfig();
    const favsCookie = useCookie(favoritesCookieKey);
    if (favsCookie.value) return;

    const likesFromStorage = localStorage.getItem('likes');
    if (likesFromStorage) {
      const likesArray = likesFromStorage.split(',').map(Number);

      favsCookie.value = JSON.stringify([...likesArray.map((i) => ({
        external_id: i,
        category: 'used',
      }))]);
    }
  },
});
